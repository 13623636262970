(function () {
  /**
     * Focus element on condition
     * (Primary used in prematch.html and ticket-check.html to focus input field after offer is loaded)
     * */
  angular.module('7Shop.Common')
    .directive('conditionalFocus', function conditionalFocus($timeout) {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          scope.$watch(function () {
            return scope.$eval(attrs.focusWhen);
          }, function (newValue) {
            if (!newValue) {
              $timeout(function () {
                element[0].focus();
              }, 0, false);
            }
          });
        }
      };
    });
})();
