(function () {
  angular.module('7Shop.Common')
    .directive('autofocus', ['$timeout',
      function autofocus($timeout) {
        return {
          restrict: 'A',
          link: function ($scope, $element, $attrs) {
            var doFocus = angular.isDefined($attrs.autofocus);
            if (doFocus) {
              $timeout(function () {
                $element[0].focus();
              }, 0);
            }
          }
        };
      }
    ]);
})();
