(function () {
  angular.module('7Shop.Common')
    .directive('scrollable', scrollable);

  function scrollable($timeout) {
    return {
      scope: {
        options: '=?'
      },
      link: function ($scope, $element) {
        function scrollToBottom() {
          $timeout(function () {
            if ($element[0].scrollTop < ($element[0].scrollHeight - $element[0].offsetHeight)) {
              $element.scrollTop($element[0].scrollHeight);
            }
          }, 0, false);
        }

        if ($scope.options) {
          $scope.options.scrollToBottom = scrollToBottom;
        }
      }
    };
  }
}());
