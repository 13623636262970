/* eslint-disable no-return-assign */
(function () {
  angular.module('7Shop.Common')
    .service('popupService', popupService);

  /**
     * @class SevenAcceptor
     * @constructor
     */
  function popupService() {
    /**
         * List of popups
         * @type {{}}
         */
    var popups = {};

    return {

      /**
             * Add popup to list
             *
             * @memberOf popupService
             * @param {Object} data
             * @param {Object} data.attr
             * @param {Object} data.scope
             * @param {Object} data.el
             */
      addPopup: function (data) {
        popups[data.attr.popupId] = data;
      },

      /**
             * Show popup
             *
             * @memberOf popupService
             * @param {String} popupId
             * @param {Object} config
             * @param {boolean} config.closeDisabled
             * @param {boolean} config.forceClose
             */
      showPopup: function (popupId, config) {
        popups[popupId].scope.showPopup(config);
      },

      /**
             * Close popup
             *
             * @param {String} popupId
             */
      closePopup: function (popupId, config) {
        popups[popupId].scope.closePopup(config);
      },

      /**
             * Get popup instance
             *
             * @memberOf popupService
             * @param {String} popupId
             * @returns {*}
             */
      getPopup: function (popupId) {
        return popups[popupId];
      },

      /**
             * Attach callback to close event
             *
             * @deprecated in favor of broadcast popUp:close:{popupId}
             * @memberOf popupService
             * @param {String} popupId
             * @param {Function} cb
             */
      onClose: function (popupId, cb) {
        popups[popupId].scope.onClose = cb;
      },

      /**
             *
             * @memberOf popupService
             * @param popupId
             * @return {boolean}
             */
      disableClose: function (popupId) {
        return popups[popupId].scope.popupCloseDisabled = true;
      },

      /**
             *
             * @memberOf popupService
             * @param popupId
             * @return {boolean}
             */
      isCloseDisabled: function (popupId) {
        return popups[popupId].scope.popupCloseDisabled;
      },

      /**
             * Close all active popups
             *
             * @memberOf popupService
             */
      closeAllPopups: function () {
        Object.keys(popups).forEach(function (key) {
          popups[key].scope.closePopup();
        });
      }
    };
  }
})();
