(function () {
  /**
     * Directive which will disable key down events from keyboard
     * It have a option to allow some keys to go through
     */
  angular.module('7Shop.Common')
    .directive('disableKeyboard', function disableKeyboard($document) {
      return {
        scope: {
          disableKeyboard: '=',
          disableKeyboardAllow: '@'
        },
        link: function (scope) {
          // grab alowed keys
          var allowedKeys = scope.disableKeyboardAllow.split(',');

          var onKey = function (e) {
            var code = '';

            // if alt is hold down
            if (e.altKey) {
              code += 'alt+';
            }

            code += e.keyCode.toString();

            // if not code is found in allowed list stop event
            if (allowedKeys.indexOf(code) === -1) {
              e.stopImmediatePropagation();
            }
          };

          scope.$watch('disableKeyboard', function (newValue) {
            if (angular.isUndefined(newValue)) return;

            if (newValue) {
              $document.bindFirst('keydown', onKey);
            } else {
              $document.off('keydown', onKey);
            }
          });
        }
      };
    });
})();
