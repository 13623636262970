import { Money } from '@nsftx/money-js/dist/bundle.umd';
/* @ngInject */
function sevenMoneyOverride(ConfigService, companyService) {
  return function (value, method) {
    var currency = companyService.data.currency;
    var cashStrategy = ConfigService.getCashStrategy();
    var money;

    if (!cashStrategy) { return value; }

    if (method.toLowerCase() === 'frommainunit') {
      money = Money.createFromMainUnit(value, currency, cashStrategy);
      return money.getAmountAsMainUnit();
    }

    money = Money.createFromSmallestUnit(value, currency, cashStrategy);
    return money.amount;
  };
}

export default sevenMoneyOverride;
