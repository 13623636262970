/* eslint-disable no-return-assign */
/* eslint-disable radix */
/* eslint-disable guard-for-in */
/* eslint-disable angular/timeout-service */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
/* eslint-disable no-prototype-builtins */
/* eslint-disable new-cap */
/* eslint-disable no-continue */
/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
(function () {
  function SevenGamesSvc(
    $injector,
    $q,
    $filter,
    nabMessaging,
    SevenClientConfig
  ) {
    var gamesMapper = {
      VirtualGreyhoundRaces: 'GreyhoundRaces',
      VirtualHorseRaces: 'HorseRaces',
      SportradarVTO: 'VirtualSports',
      SportradarVFL: 'VirtualSports',
      SportradarVirtualFootball: 'VirtualSports',
      SportradarVBL: 'VirtualSports'
    };

    var categories = {
      NGS: {
        id: 'NGS',
        clientId: 'virtual-games',
        type: 'product-group',
        games: []
      },
      Sportsbook: {
        id: 'Sportsbook',
        clientId: 'sportsbook',
        type: 'product-group',
        games: []
      }
    };

    /**
         * counterTreshold - defines how many times config should be re-fetched
         * timeout - timeout before re-fetch action run
         */

    var REFETCH_CONFIGURATION = {
      firstInterval: {
        counterTreshold: 5,
        timeout: 30000
      },
      secondInterval: {
        counterTreshold: 8,
        timeout: 60000
      }
    };

    function SevenGames() {
      this.games = [];
      this.failedGames = [];
      this.activeGame = null;
      this.refetchCounter = {};
      this.refetchAction = {};
    }

    SevenGames.prototype = {

      init: function (gamesData) {
        var deffered = $q.defer();
        var promises = {};
        var self = this;
        this.gamesData = gamesData;

        // init SevenGames
        for (var i = 0; i < gamesData.length; i++) {
          var gameData = gamesData[i];

          /**
                     * @clientId - used for different games that are supposed to have
                     * same client-side game and betting logic. Real product id must
                     * not be overwritten as it's necessary for communication with backend
                     * e.g. Greyhound Races and Virtual Greyhound Races are different
                     * games but they are both supposed to use GreyhoundRaces module
                     */
          gameData.clientId = gamesMapper[gameData.id] ? gamesMapper[gameData.id] : gameData.id;
          // skip game registration if game is already added
          if (this.getGame(gameData.id)) {
            continue;
          }

          promises[gameData.id] = this.setGameData(gameData);
        }

        $q.all(promises).then(function (response) {
          self.sortByPriority();
          self.setDefaultActiveGame();
          deffered.resolve(response);
        });

        return deffered.promise;
      },

      setGameData: function (gameData) {
        var gameObj = null;
        var deffered = $q.defer();
        var self = this;

        // New products (e.g. SM) do not need to extend from our service.
        // We just need to keep normal instance of SevenGameSvc with data from platform
        if (gameData.local.initOwnService !== false
                  && $injector.has(gameData.clientId + 'GameService')) {
          gameObj = $injector.get(gameData.clientId + 'GameService');
        } else {
          gameObj = $injector.get('SevenGameSvc');
        }

        this.registerGame(new gameObj(gameData)).then(function (response) {
          if (self.getFailedGame(gameData.id) !== false) {
            self.updateRefetchedGame(response);
            self.sortByPriority();
          }
        }, function (response) {
          self.refetchConfig(response);
        }).finally(function (response) {
          deffered.resolve(response);
        });

        return deffered.promise;
      },

      registerGame: function (newGame) {
        var deffered = $q.defer();
        var self = this;

        newGame.init().then(function (data) {
          if (data.response.status !== 200 || !data.gameInstance.config) {
            if (self.getFailedGame(data.gameInstance.id) === false) {
              self.failedGames.push(data.gameInstance);
            }
            deffered.reject(data.gameInstance);
          } else {
            self.games.push(data.gameInstance);

            // add it to appropriate category
            if (categories.hasOwnProperty(data.gameInstance.category)) {
              categories[data.gameInstance.category].games.push(data.gameInstance);
            }

            deffered.resolve(data.gameInstance);
          }
        });

        return deffered.promise;
      },

      /**
             * Re-fecth config with timeout set in REFETCH_CONFIGURATION
             */
      refetchConfig: function (gameInstance) {
        var self = this;
        var refetchTimeout = null;

        if (!this.refetchCounter[gameInstance.id]) {
          this.refetchCounter[gameInstance.id] = 1;
        } else {
          this.refetchCounter[gameInstance.id]++;
        }

        // Set re-fetch delay
        for (var key in REFETCH_CONFIGURATION) {
          if (this.refetchCounter[gameInstance.id] <= REFETCH_CONFIGURATION[key].counterTreshold) {
            refetchTimeout = REFETCH_CONFIGURATION[key].timeout;
            break;
          }
        }

        // Config fetched too many times so abort the action
        if (!refetchTimeout) {
          clearTimeout(this.refetchAction[gameInstance.id]);
          nabMessaging.publish('app:configFetchingFailed', gameInstance.info.translation);
          return false;
        }

        this.refetchAction[gameInstance.id] = setTimeout(
          function () {
            self.setGameData(gameInstance.info);
          },
          refetchTimeout
        );
      },

      updateRefetchedGame: function (game) {
        var self = this;
        var failedGameIndex;

        clearTimeout(self.refetchAction[game.id]);
        nabMessaging.publish('app:configFetchingSuccess', game);
        failedGameIndex = this.failedGames.findIndex(function (element) {
          return element.id === game.id;
        });

        if (failedGameIndex !== -1) {
          this.failedGames.splice(failedGameIndex, 1);
        }
      },

      sortByPriority: function () {
        for (var i in categories) {
          categories[i].games = $filter('orderBy')(categories[i].games, function (game) {
            return parseInt(game.info.priority);
          });
        }
      },

      setDefaultActiveGame: function () {
        if (!this.activeGame && this.games.length) {
          this.activeGame = this.games[0];
        }
      },

      setActiveGame: function (id) {
        var foundGame = this.getGame(id);

        if (foundGame) {
          return this.activeGame = foundGame;
        }

        return false;
      },

      resetActiveGame: function () {
        this.activeGame = null;
      },

      /**
             * Return active game object
             */
      getActiveGame: function () {
        return this.activeGame;
      },

      /**
             * Return ID of active game
             */
      getActiveGameId: function () {
        if (this.activeGame) {
          return this.activeGame.id;
        }
        return null;
      },

      getGame: function (id) {
        var foundGames = $filter('filter')(this.games, { id: id }, true);
        return foundGames.length ? foundGames[0] : false;
      },

      /**
             * Return list of active games ordered
             * by priority
             *
             * @param {Object} config
             * @param {Number} config.limit - Limit number of returned gams
             * @returns {*}
             */
      getGames: function (config) {
        config = config || {};

        // order games by priority
        var games = $filter('orderBy')(this.games, function (game) {
          return parseInt(game.info.priority);
        });

        // limit games to return
        return games.slice(0, config.limit || games.length);
      },

      /**
             * Get game which failed to load
             */
      getFailedGame: function (id) {
        var foundGames = $filter('filter')(this.failedGames, { id: id }, true);
        return foundGames.length ? foundGames[0] : false;
      },

      getCategories: function () {
        return categories;
      },

      /**
             * Get product group with list of games
             * @param category
             * @returns {*}
             */
      getProductGroup: function (category) {
        for (var i in categories) {
          var cat = categories[i];

          if (cat.clientId === category) {
            return cat;
          }
        }

        return false;
      },

      /**
             * Get list of product channels to connect on SCM
             *
             * @returns {{}}
             */
      getGamesChannels: function () {
        var games = this.getGames();
        var channels = {};

        for (var i = 0; i < games.length; i++) {
          var gameData = games[i];
          var localConfig = gameData.info.local;

          if (localConfig.connectToScm) {
            channels[gameData.id] = {
              cpvUuid: gameData.info.cpvUuid,
              // also add local ncm config data to object
              subscribeConfig: (localConfig.scm && localConfig.scm.subscribeConfig) ? localConfig.scm.subscribeConfig : {}
            };
          }
        }

        return channels;
      },

      /**
             * Return locally defined game configuration.
             * Useful when game is inactive but you still need some game
             * confirmation defined by programmer.
             *
             * @param gameId
             * @returns {*}
             */
      getLocalConfig: function (gameId) {
        return SevenClientConfig.getSettings().modules.games[gameId];
      }
    };

    return new SevenGames();
  }

  angular.module('7Shop.Core')
    .service('SevenGamesSvc', SevenGamesSvc);
})();
