(function () {
  angular.module('7Shop.Common')
    .provider('SevenClientLibConfig', function SevenClientLibConfigProvier() {
      return {

        settings: {
          scroll: {
            templateUrl: 'src/lib/src/directives/customScroll/templates/scrollButtons.html',
            showNavigation: true
          },
          numPad: {
            templateUrl: 'src/lib/src/directives/numPad/templates/numPad.html'
          },
          loader: {
            // automatically show/hide loader on each http request
            auto: false,
            // if auto is on
            // it won't show loader if one of these requests are running
            blackList: [
              'http://localhost:5051/get_status',
              'http://localhost:8078/start',
              'http://localhost:8078/stop',
              'http://127.0.0.1:8080'
            ]
          },
          popup: {
            templateUrl: false
          }
        },

        $get: function () {
          return this.settings;
        },
        set: function (newSettings) {
          return angular.merge(this.settings, newSettings);
        }

      };
    });
}());
