// Send notification to specific popup template

(function () {
  angular.module('7Shop.Common')
    .directive('popupNotification', function popupNotification($rootScope, popupService) {
      return {
        restrict: 'A',
        scope: {
          popupId: '@',
          popupInactive: '=',
          popupData: '<'
        },

        link: function (scope, el, attr) {
          el.on('click', function (e) {
            e.stopPropagation();

            if (scope.popupInactive) {
              return;
            }

            if (attr.popupAction === 'open') {
              if (scope.popupData) {
                popupService.addPopup({ attr: attr, popupData: scope.popupData });
              }
              popupService.showPopup(scope.popupId);
            } else if (attr.popupAction === 'close') {
              popupService.closePopup(scope.popupId);
            }
          });
        }
      };
    });
})();
