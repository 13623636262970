(function () {
  angular.module('7Shop.Core')
    .directive('isTouch', function isTouch(Touch) {
      return {
        restrict: 'A',
        link: function (scope, elem) {
          // Type of device can be passed through bindings if needed (ios, android, tablet, mobile, etc)
          if (!Touch.isTouch()) {
            elem.remove();
          }
        }
      };
    });
})();
