/**
* @class GravitySettingsProvider
* @memberof module:"7Shop.Core"
*/
/* @ngInject */
function GravitySettingsProvider() {
  var self = this;

  /**
   * @memberOf module:"7Shop.Core".GravitySettings
   */
  this._data = {};

  /**
   * @memberOf module:"7Shop.Core".GravitySettings
   */
  this.setData = function (applicationSettings) {
    this._data = applicationSettings;
  };

  /**
     * @memberOf module:"7Shop.Core".GravitySettings
     */
  this.getByKey = function (key) {
    return this._data[key];
  };

  /**
     * @memberOf module:"7Shop.Core".GravitySettings
     * @param {String} moduleName
     * @param {String} key
     * @returns {*}
     */
  this.getModuleDataKeyValue = function (moduleName, key) {
    var moduleData = this.getByKey(moduleName) || {};
    return moduleData[key];
  };

  /**
     * @memberOf module:"7Shop.Core".GravitySettings
     * @param {String} moduleName
     * @param {Object} data
     * @returns {*}
     */
  this.updateSetting = function (moduleName, newData) {
    var moduleData = this.getByKey(moduleName);

    if (moduleData) {
      angular.extend(moduleData, newData);
    }
  };

  /**
     * @memberOf module:"7Shop.Core".GravitySettings
     * @returns {*}
     */
  this.getData = function () {
    return this._data;
  };

  /**
     *
     */
  this.$get = function () {
    return self;
  };
}

export default GravitySettingsProvider;
