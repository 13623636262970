/* eslint-disable angular/controller-as, angular/controller-name */
(function () {
  angular.module('7Shop.Common')
    .controller('multiSelectItems', multiSelectItems);

  function multiSelectItems(
    $scope,
    close
  ) {
    $scope.close = close;
  }
})();
