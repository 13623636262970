(function () {
  angular.module('7Shop.Core')
    .filter(
      'isShortcutActive',
      /**
       *
       * @memberof module:"7Shop.Core"
       * @name isShortcutActive
       */
      function isShortcutActive(Shortcuts) {
        /**
         * @memberof  module:"7Shop.Core".isShortcutActive
         * @param input {String}
         */
        return function (input) {
          if (angular.isUndefined(input)
            || Shortcuts.isShortcutDisabled(input)) {
            return '';
          }

          return input;
        };
      }
    );
})();
