import PubSub from 'pubsub-js';

(function (window) {
  function nabMessaging() {
    /**
         * Available implementations of pub/sub
         * @type {{PubSubJs: Function}}
         */
    var DRIVERS = {
      PubSubJs: function () {
        return PubSub;
      }
    };

    /**
         * Wrapper around PubSubJs to implement nabMessaging interface
         * @return {{publish: Function, subscribe: Function, unsubscribe: Function, clearAllSubscriptions: Function}}
         * @constructor
         */
    function PubSubJs() {
      return {
        publish: function (eventName, data, config) {
          if (config && config.sync) {
            DRIVERS.PubSubJs().publishSync(eventName, data);
          } else {
            DRIVERS.PubSubJs().publish(eventName, data);
          }
        },

        subscribe: function (eventName, callback) {
          return DRIVERS.PubSubJs().subscribe(eventName, callback);
        },

        unsubscribe: function (eventName) {
          DRIVERS.PubSubJs().unsubscribe(eventName);
        },

        clearAllSubscriptions: function () {
          DRIVERS.PubSubJs().clearAllSubscriptions();
        }
      };
    }

    function getPubSubService() {
      return PubSubJs();
    }

    return {

      /**
             * Publish message
             * @param {String} eventName
             * @param {*} data
             * @param {Object} config
             */
      publish: function (eventName, data, config) {
        getPubSubService().publish(eventName, data, config);
      },

      /**
             * Subscribe to event
             * @param {String} eventName
             * @param {Function} callback
             * @return {String} token of subscription
             */
      subscribe: function (eventName, callback) {
        return getPubSubService().subscribe(eventName, callback);
      },

      /**
             * Un subscribe from event
             * @param {String} token - token of subscription
             */
      unsubscribe: function (token) {
        getPubSubService().unsubscribe(token);
      },

      /**
             * Clear all subscription
             */
      clearAllSubscriptions: function () {
        getPubSubService().clearAllSubscriptions();
      },

      /**
             * Publish message
             *
             * @deprecated
             * @param {String} eventName
             * @param {*} data
             */
      doGlobalEmit: function (eventName, data) {
        this.publish(eventName, data);
      },

      /**
             * Publish message
             *
             * @deprecated
             * @param {String} eventName
             * @param {*} data
             */
      doGlobalBroadcast: function (eventName, data) {
        this.publish(eventName, data);
      },

      /**
             * Subscribe to event
             *
             * @deprecated
             * @param {String} eventName
             * @param {Function} callback
             */
      addGlobalSubscription: function (eventName, callback) {
        return this.subscribe(eventName, function (event, data) {
          if (typeof callback === 'function') {
            callback(event, data);
          }
        });
      }
    };
  }

  // eslint-disable-next-line angular/window-service
  window.nabMessaging = nabMessaging();

  angular.module('7Shop.Common')
    .factory('nabMessaging', function nabMessagingService() {
      // eslint-disable-next-line angular/window-service
      return window.nabMessaging;
    });
})(window);
