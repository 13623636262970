angular.module('7Shop.Core').service('SevenGameSvc', function SevenGameService(
  $injector,
  $http,
  $sce,
  $q,
  SevenClientRules,
  SevenClientConfig
) {
  /**
     * Seven game instance
     *
     * @param gameData
     * @constructor SevenGameSvc
     */
  function SevenGameSvc(gameData) {
    this.id = gameData.id;
    this.clientId = gameData.clientId;
    this.info = gameData;
    this.config = null;
    this.refetchTimer = 0;
    this.refetchAction = null;

    /**
         * Keeps instance of betting service
         * @type {null}
         * @private
         */
    this._bettingSvc = {

      // Keeps instance of rules service
      _rulesSvc: null
    };
  }

  /**
     * Init game
     *
     * @returns {*}
     */
  SevenGameSvc.prototype.init = function () {
    var RulesSvc = null;
    var BettingSvc = null;

    // first check do we have services
    if ($injector.has('nab' + this.clientId + 'Rules')) {
      RulesSvc = $injector.get('nab' + this.clientId + 'Rules');
    }

    if ($injector.has('nab' + this.clientId + 'BettingService')) {
      BettingSvc = $injector.get('nab' + this.clientId + 'BettingService');
    }

    // create betting service
    if (angular.isFunction(BettingSvc)) {
      this._bettingSvc = new BettingSvc(this.id);
    } else {
      this._bettingSvc = BettingSvc;
    }

    // create rules service
    if (angular.isFunction(RulesSvc)) {
      this._bettingSvc._rulesSvc = new RulesSvc();
    } else if (RulesSvc) {
      this._bettingSvc._rulesSvc = RulesSvc;
    }

    // Here we can skip fetching seven config.
    // Products inside iframe does not need to fetch config
    if (this.info.local && this.info.local.fetchSevenConfig === false) {
      this.config = {};
      return $q.resolve({
        response: { status: 200 },
        gameInstance: this
      });
    }

    return this.setConfig(this.info.configRoute);
  };

  /**
     * Set game config to instance
     *
     * @param url
     * @returns {*}
     */
  SevenGameSvc.prototype.setConfig = function (url) {
    var self = this;
    var fetchUrl = url || this.info.configRoute;
    var config = {
      headers: { 'SEVEN-LOCALE': SevenClientConfig.getSettings().global.language },
      timeout: 10000
    };

    return this.fetchConfig(fetchUrl, config).then(
      function (response) {
        // config fetched
        self.config = response.data.config;

        // add hardcoded rules
        if (angular.isArray(self.config.rules)) {
          self.config.rules = self.config.rules.concat(SevenClientRules.rules);
        } else {
          angular.merge(self.config.rules, SevenClientRules.rules);
        }

        // update rules inside rules service
        if (self.getRulesSvc()) {
          self.getRulesSvc().rules = self.config.rules;
        }

        // return instance of game and response
        return {
          response: response,
          gameInstance: self
        };
      },
      function (response) {
        // fetching config failed
        return {
          response: response,
          gameInstance: self
        };
      }
    );
  };

  SevenGameSvc.prototype.getTaxesConfig = function () {
    var moduleConfig = SevenClientConfig.getProductSettings(this.id);
    return this.config.taxes || moduleConfig.tax || null;
  };

  /**
     * Fetch config from server
     *
     * @param fetchUrl
     * @param config
     * @returns {HttpPromise}
     */
  SevenGameSvc.prototype.fetchConfig = function (fetchUrl, config) {
    return $http.get(fetchUrl, config);
  };

  /** Return rules service
    * @returns {null|*|RulesSvc}
    * */
  SevenGameSvc.prototype.getRulesSvc = function () {
    return (this._bettingSvc && this._bettingSvc._rulesSvc) || null;
  };

  /**
     * Return betting service
     *
     * @returns {null|*|BettingSvc}
     */
  SevenGameSvc.prototype.getBettingSvc = function () {
    return this._bettingSvc;
  };

  /**
     * Change active odd type
     * if odd type is available
     *
     * @param {string} oddType - Typo of odd like American, Decimal...
     * @returns {*}
     */
  SevenGameSvc.prototype.setOddType = function (oddType) {
    // does game support odd type?
    if (this.config.odds && this.config.odds.supported.indexOf(oddType) >= 0) {
      // set new active odd type
      // eslint-disable-next-line no-return-assign
      return this.config.odds.active = oddType;
    }

    return false;
  };

  /**
     * Return shop specific game info
     * Data used across shop for games, betslip...
     */
  SevenGameSvc.prototype.getGameInfo = function () {
    var gameInfo = {};
    gameInfo.id = this.id;
    gameInfo.clientId = this.clientId;
    gameInfo.info = this.info;
    gameInfo.data = this.gameInfo || false;

    return gameInfo;
  };

  SevenGameSvc.prototype.updateLocalSettings = function (config) {
    angular.merge(this.info.local, config);
  };

  return SevenGameSvc;
});
