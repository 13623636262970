(function () {
  angular.module('7Shop.Common')
    .directive('customSelect', function customSelect() {
      return {
        scope: {
          customData: '=',
          customDefault: '=',
          customSort: '='
        },
        require: 'ngModel',
        link: function (scope, elem, attrs, modelCtrl) {
          var inputIndex;
          var selectedIndex;
          /**
                 * customDefault - default value of select
                 * customData param must be array
                 *
                 * If there isn't default value, value on index 0 will be displayed
                 */

          if (scope.customSort) {
            scope.customData.sort(function (a, b) { return a - b; });
          }

          inputIndex = scope.customData.indexOf(scope.customDefault);
          // eslint-disable-next-line
          selectedIndex = inputIndex != -1 ? inputIndex : 0;

          modelCtrl.$setViewValue(scope.customData[selectedIndex]);
          modelCtrl.$render();

          function pickNextValue() {
            if (scope.customData[selectedIndex - 1]) {
              modelCtrl.$setViewValue(scope.customData[selectedIndex - 1]);
              modelCtrl.$render();

              selectedIndex -= 1;
            }
          }

          function pickPreviousValue() {
            if (scope.customData[selectedIndex + 1]) {
              modelCtrl.$setViewValue(scope.customData[selectedIndex + 1]);
              modelCtrl.$render();

              selectedIndex += 1;
            }
          }

          scope.$on('CustomSelect.PickNextValue', function () {
            pickNextValue();
          });

          scope.$on('CustomSelect.PickPreviousValue', function () {
            pickPreviousValue();
          });

          elem.on('keydown', function (e) {
            if (e.which === 40) { // Key down
              pickNextValue();
            } else if (e.which === 38) {
              pickPreviousValue(); // Key up
            }
          });

          /**
                 * When custom data changes, check is previously selected item still avaialable
                 */
          scope.$watch('customData', function (current) {
            if (current.indexOf(modelCtrl.$modelValue) < 0) {
              inputIndex = scope.customData.indexOf(scope.customDefault);
              // eslint-disable-next-line
              selectedIndex = inputIndex != -1 ? inputIndex : 0;

              modelCtrl.$setViewValue(scope.customData[selectedIndex]);
              modelCtrl.$render();
            } else {
              inputIndex = scope.customData.indexOf(modelCtrl.$modelValue);
              // eslint-disable-next-line
              selectedIndex = inputIndex != -1 ? inputIndex : 0;

              modelCtrl.$setViewValue(scope.customData[selectedIndex]);
              modelCtrl.$render();
            }
          }, true);
        }
      };
    });
})();
