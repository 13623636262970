(function () {
  angular.module('7Shop.Common').factory('LocalStorage', LocalStorage);

  /**
   *
   * @class LocalStorage
   * @memberof module:"7Shop.Common"
   */
  function LocalStorage($window) {
    var storage = $window.localStorage;

    return {
      /**
       * @memberof module:"7Shop.Common".LocalStorage
       */
      getStorage: function () {
        return this;
      },

      /**
       * @memberof module:"7Shop.Common".LocalStorage
       */
      setItem: function (name, data) {
        var storageData;

        try {
          // Chrome storage accepts items in object notation
          storageData = {};
          storageData[name] = data;
          return storage.set(storageData, function () {});
        } catch (e) {
          return storage.setItem(name, data);
        }
      },

      /**
       * Chrome storage get is async
       * Use js Promise for implementation
       *
       * @memberof module:"7Shop.Common".LocalStorage
       * @param name
       * @returns {Promise}
       */
      getItem: function (name) {
        return new Promise(function (resolve) {
          try {
            storage.get(name, function (data) {
              // data is returned in object notation
              resolve(data[name]);
            });
          } catch (e) {
            resolve(storage.getItem(name));
          }
        });
      },

      /**
       * @memberof module:"7Shop.Common".LocalStorage
       * @param {*} name
       */
      removeItem: function (name) {
        try {
          return storage.remove(name);
        } catch (e) {
          return storage.removeItem(name);
        }
      }
    };
  }
})();
