import { find } from 'underscore';

/* @ngInject */
function KeyEventsSvc(
  $rootScope,
  $log,
  $filter,
  KeyMapper,
  SevenClientCoreSvc,
  SevenGamesSvc,
  switchView,
  Shortcuts
) {
  return {
    processKeyEvent: function (e) {
      var code = String.fromCharCode(e.keyCode).toLowerCase();
      var specialKeys = '';
      var finalCode = '';
      var foundAction = false;
      var foundShortcut = false;
      var system;
      var keyName;
      var game;

      specialKeys += e.ctrlKey ? 'ctrl+' : '';
      specialKeys += e.altKey ? 'alt+' : '';
      specialKeys += e.shiftKey ? 'shift+' : '';

      // If specialKeys is not ready
      // splice from end '+' and set code to empty. For example if we press
      // only shift we don't want to broadcast 'shit+shiftKeyCode' or 'ctrl+ctrlKeyCode'.
      // Result from this is just pure 'shift' or 'ctrl' string.
      if ([KeyMapper.shift_left.key, KeyMapper.alt_left.key, KeyMapper.ctrl_left.key].indexOf(e.keyCode) >= 0
        && specialKeys.charAt(specialKeys.length - 1) === '+') {
        // also erase shift/alt/ctrl code
        code = '';
        // strip +
        specialKeys = specialKeys.substring(0, specialKeys.length - 1);
      }

      finalCode = specialKeys + code;

      // alt + ctrl + something
      if ((e.altKey || e.ctrlKey)
        && e.keyCode !== KeyMapper.shift_left.key) {
        foundAction = Shortcuts.getByShortcut(finalCode);
        // don't broadcast if shortcut does not exist
        if (foundAction.length) {
          $rootScope.$evalAsync(function () {
            $log.info('[Sportsbook.Core] KeyPressed', {
              action: foundAction[0].action
            });
            $rootScope.$broadcast('7S:KeyPressed:' + foundAction[0].action, {
              code: finalCode,
              e: e
            });
          });
        }
        return;
      }

      switch (e.keyCode) {
      case KeyMapper.letter_c.key:
      case KeyMapper.letter_d.key:
      case KeyMapper.letter_e.key:
      case KeyMapper.letter_f.key:
      case KeyMapper.letter_g.key:
      case KeyMapper.letter_j.key:
      case KeyMapper.letter_h.key:
      case KeyMapper.letter_k.key:
      case KeyMapper.letter_l.key:
      case KeyMapper.letter_m.key:
      case KeyMapper.letter_o.key:
      case KeyMapper.letter_p.key:
      case KeyMapper.letter_q.key:
      case KeyMapper.letter_r.key:
      case KeyMapper.letter_s.key:
      case KeyMapper.letter_t.key:
      case KeyMapper.letter_w.key:
      case KeyMapper.letter_i.key:
        $rootScope.$evalAsync(function () {
          $rootScope.$broadcast('KeyPressed', code);
        });
        break;

      case KeyMapper.number_1.key:
      case KeyMapper.number_2.key:
      case KeyMapper.number_3.key:
      case KeyMapper.number_4.key:
      case KeyMapper.Numpad1.key:
      case KeyMapper.Numpad2.key:
      case KeyMapper.Numpad3.key:
      case KeyMapper.Numpad4.key:
        system = find(KeyMapper, function (value) {
          return e.keyCode === value.key;
        });

        if (SevenGamesSvc.activeGame) {
          $rootScope.$broadcast(
            SevenGamesSvc.activeGame.id + ':specialOutcome',
            system.text
          );
        }
        break;
      case KeyMapper.f1.key: // f1
      case KeyMapper.f2.key:
      case KeyMapper.f3.key:
      case KeyMapper.f4.key:
      case KeyMapper.f5.key:
      case KeyMapper.f5_v1.key:
      case KeyMapper.f6.key:
      case KeyMapper.f7.key:
      case KeyMapper.f8.key: // f8
      case KeyMapper.f9.key: // f9
      case KeyMapper.f10.key:
      case KeyMapper.f11.key:// f11
        keyName = find(KeyMapper, function (value) {
          return e.keyCode === value.key;
        });

        keyName = keyName.text.toUpperCase();

        // get game based on keyName
        // shortcut is set on games configuration phase
        game = $filter('filter')(SevenClientCoreSvc.getAllModules(), function (module) {
          return module.shortcut === keyName
            || (module.info && module.info.shortcut === keyName);
        });

        if (!game[0]) {
          return;
        }

        if (!switchView.isServiceActive(game[0].id)) {
          switchView.selectService(game[0]);
        } else {
          $rootScope.$evalAsync(function () {
            $rootScope.$broadcast('KeyPressed', '+');
          });
        }

        // OverlaySvc.close();

        break;
      // code 'new ticket'
      // internet shows that this code is for 'equal', our laptops for '+'
      case KeyMapper.plus.key:
      case KeyMapper.numpad_plus.key:
        code = '+';

        $rootScope.$evalAsync(function () {
          $rootScope.$broadcast('KeyPressed', code);
        });

        break;
      case KeyMapper.asterisk.key:
        code = '*';
        $rootScope.$evalAsync(function () {
          $rootScope.$broadcast('KeyPressed', code);
        });

        break;
      case KeyMapper.escape.key:
        code = 'esc';
        $rootScope.$evalAsync(function () {
          $rootScope.$broadcast('KeyPressed', code);
        });
        // Don't add break because esc needs to be emitted also
      default: // eslint-disable-line
        foundShortcut = Shortcuts.findByKeyCode(e.keyCode);

        if (foundShortcut) {
          $rootScope.$evalAsync(function () {
            $rootScope.$broadcast('7S:KeyPressed:' + foundShortcut.action, {
              code: finalCode,
              e: e
            });
          });
        }
        break;
      }
    }
  };
}

export default KeyEventsSvc;
