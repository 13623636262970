/* eslint-disable no-restricted-syntax */
export default function NabValidationConfig() {
  return {
    $get: function () {
      return this.options;
    },
    set: function (keyOrHash, value) {
      var k;
      var v;
      var _results;
      if (typeof keyOrHash === 'object') {
        _results = [];
        // eslint-disable-next-line guard-for-in
        for (k in keyOrHash) {
          v = keyOrHash[k];
          _results.push(this.options[k] = v);
        }
        return _results;
      }
      // eslint-disable-next-line no-return-assign
      return this.options[keyOrHash] = value;
    }
  };
}
