/* eslint-disable angular/window-service */
(function (window) {
  function PubSub() {
    return {
      publish: window.nabMessaging.publish,
      publishSync: function (eventName, data) {
        return window.nabMessaging.publish(eventName, data, { sync: true });
      },
      subscribe: window.nabMessaging.subscribe,
      unsubscribe: window.nabMessaging.unsubscribe,
      clearAllSubscriptions: window.nabMessaging.clearAllSubscriptions
    };
  }

  window._7.registerService('PubSub', PubSub);
})(window);
