(function () {
  /**
     @typedef {Object} currency
     @property {string} value=''
     @property {string} position=suffix
     @property {{SUFFIX: 'suffix', PREFIX: prefix}} CURRENCY_POSITIONS
     */

  /**
     * Enum for tri-state values.
     * @readonly
     * @enum {string}
     */
  var CURRENCY_POSITIONS = {
    /** @type {string} */
    SUFFIX: 'suffix',
    /** @type {string} */
    PREFIX: 'prefix'
  };

  /**
     * @type {currency}
     */
  var currency = {
    value: '',
    position: CURRENCY_POSITIONS.SUFFIX,
    CURRENCY_POSITIONS: CURRENCY_POSITIONS
  };

  // keep for backward compatibility
  angular.module('7Shop.Common')
    .value('currency', currency);
})();
