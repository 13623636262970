(function () {
  angular.module('7Shop.Common')
    .directive('inputRestoreValue', inputRestoreValue);

  function inputRestoreValue() {
    return {
      require: 'ngModel',
      link: function ($scope, $element, $attrs, modelCtrl) {
        var oldValue;
        var regex;

        modelCtrl.$parsers.push(function (viewValue) {
          // when nan is sent return empty
          // eslint-disable-next-line
          if (isNaN(viewValue)) {
            return '';
          }

          regex = new RegExp($attrs.ngPattern);
          if (!regex.test(viewValue)) {
            oldValue = modelCtrl.$modelValue;
            modelCtrl.$setViewValue(oldValue);
            modelCtrl.$render();
            return oldValue;
          }
          return viewValue;
        });
      }
    };
  }
})();
