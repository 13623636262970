(function () {
  angular.module('7Shop.Core')
    .factory('ConfigService', ConfigService);

  /**
   *
   * @class ConfigService
   */
  function ConfigService(GravitySettings, LoginData) {
    return {
      /**
       * State of settings, used in view
       * @memberOf ConfigService
       */
      data: {},

      setData: function () {
        var currencyDisplayValue = GravitySettings.getModuleDataKeyValue('currency', 'displayValue');

        this.data = angular.extend(
          {
            currency: currencyDisplayValue !== undefined ? currencyDisplayValue
              : LoginData.company && LoginData.company.currency,
            activeLang: (LoginData.user && LoginData.user.language) || 'en',
            dateFormat: {
              value: 'dd-MM-yyyy'
            },
            numbersFormat: {
              value: '#,###.##',
              decimalSeparator: '.',
              thousandsSeparator: ','
            },
            ticketActions: LoginData.ticketActions
          },
          LoginData.appConfig
        );
      },

      getCashStrategy: function () {
        return this.data && this.data.aggregatorSettings
        && this.data.aggregatorSettings.moneyOverride
        && this.data.aggregatorSettings.moneyOverride.cashStrategy;
      }
    };
  }
})();
