/**
* @class equals
* @memberof module:"7Shop.Core"
*/
/* @ngInject */
function sEquals() {
  return {
    restrict: 'A',
    require: '?ngModel',
    link: function (scope, elem, attrs, ngModel) {
      var validate = function () {
        var val1 = ngModel.$viewValue;
        var val2 = attrs.sEquals;
        ngModel.$setValidity('sEquals', !val1 || !val2 || val1 === val2);
      };

      if (!ngModel) return;

      // watch own value and re-validate on change
      scope.$watch(attrs.ngModel, function () {
        validate();
      });

      // observe the other value and re-validate on change
      attrs.$observe('sEquals', function () {
        validate();
      });
    }
  };
}

export default sEquals;
