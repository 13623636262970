(function () {
  angular.module('7Shop.Bootstrap')
    .factory('tokenInterceptor', tokenInterceptor);

  function tokenInterceptor(
    NabENV,
    SevenRoutes,
    CloudflareService
  ) {
    function isValidUrl(url) {
      var whiteList = [
        CloudflareService.generateCloudflareUrl(NabENV.api.ngs.london),
        CloudflareService.generateCloudflareUrl(NabENV.api.ngs.ro),
        CloudflareService.generateCloudflareUrl(SevenRoutes.mainUrl)
        // CloudflareService.generateCloudflareUrl(NabENV.api.gravity.url),
        // CloudflareService.generateCloudflareUrl(NabENV.reporting.url)
      ];
      var taxAuthUrl = url.indexOf('tax-authority') !== -1;
      var i;
      var whiteListedUrl;

      for (i = 0; i < whiteList.length; i += 1) {
        whiteListedUrl = whiteList[i];
        if (url.substring(0, whiteListedUrl.length) === whiteListedUrl) {
          return true;
        }
      }

      return taxAuthUrl || false;
    }

    return {
      request: function (request) {
        if (isValidUrl(request.url)) {
          if (sessionStorage.shopToken && !request.headers.Authorization) {
            request.headers.Authorization = 'Bearer ' + sessionStorage.shopToken;
          }

          return request;
        }

        return request;
      }
    };
  }
})();
