import prematchTemplate from '../../games/prematch/prematch.html';
import prematchTouchTemplate from '../../games/prematch/touch/prematch.html';
import liveTemplate from '../../games/live_betting/live.html';
import liveTouchTemplate from '../../games/live_betting/touch/live.html';
import livefullOfferTemplate from '../../games/live_betting/full_offer/fullOffer.html';
import livefullOfferEventTemplate from '../../games/live_betting/full_offer/event_view/eventView.html';

(function () {
  angular.module('7Shop.Core').constant('ApplicationStates', {
    shopApp: {
      'app.home.touchdetection': {
        url: '/touchdetection',
        template: {
          default: '<div class="max-size"><h1>Desktop layout</h1><br><h3 '
          + 'is-desktop>Desktop directive</h3><h3 is-touch>Touch directive</h3></div>',
          mobile: '<div class="max-size"><h1>Touch layout</h1><br><h3'
          + ' is-desktop>Deskotp directive</h3><h3 is-touch>Touch directive</h3></div>'
        },
        resolve: {
          loaded: function ($log) {
            $log.info('[Sportsbook.Touch] app.home.touchdetection loaded');
            return true;
          }
        },
        data: {
          test: true
        }
      }
    },
    PreMatchApp: {
      'app.home.games.PreMatchBetting': {
        url: '/prematch',
        templateUrl: {
          default: prematchTemplate,
          mobile: prematchTouchTemplate
        },
        controller: 'PrematchController',
        data: {
          product: 'PreMatchBetting'
        }
      }
    },
    LiveBettingApp: {
      'app.home.games.LiveBetting': {
        url: '/live',
        templateUrl: {
          default: liveTemplate,
          mobile: liveTouchTemplate
        },
        controller: 'LiveController',
        data: {
          product: 'LiveBetting'
        }
      },
      'app.home.games.FullOffer': {
        url: '/fullOffer',
        templateUrl: {
          default: livefullOfferTemplate,
          mobile: livefullOfferTemplate
        },
        controller: 'FullOfferController',
        data: {
          product: 'LiveBetting'
        },
        onEnter: /* @ngInject */ ($rootScope) => {
          $rootScope.$emit('7S:UI.Hide', {
            name: ['BettingArea'],
            productId: 'LiveBetting'
          });
        }
      },
      'app.home.games.FullOfferEvent': {
        url: '/fullOfferEvent/:displayId',
        templateUrl: {
          default: livefullOfferEventTemplate,
          mobile: livefullOfferEventTemplate
        },
        controller: 'FullOfferEventController',
        onEnter: /* @ngInject */ ($rootScope) => {
          $rootScope.$emit('7S:UI.Hide', {
            name: ['BettingArea'],
            productId: 'LiveBetting'
          });
        }
      }
    }
  });
})();
