/* @ngInject */
export default function nabTrans($q, $translate) {
  var deferred = $q.defer();
  var transObj = {};

  /**
   * @constructor nabTrans
   */
  return {

    /**
       * All trans data is received on bootstrap and set here
       * @param data
       * @memberOf nabTrans
       */
    setTrans: function (data) {
    // eslint-disable-next-line
    for (var i in data) {
      // eslint-disable-next-line
      if (!data.hasOwnProperty(i)) {
          return;
        }

        transObj[i] = {};
        transObj[i] = data[i];
      }

      deferred.resolve(transObj);
    },

    /**
       * Returns promise to transObj that gets populated on login
       * @memberOf nabTrans
       * @returns {*}
       */
    getTransObj: function () {
      return deferred.promise;
    },

    /**
       * This changes language of app, in case it is not found,
       * nabTransBootstrap gets triggered
       * @memberOf nabTrans
       * @param {string} lang
       */
    setTransLanguage: function (lang) {
      $translate.use(lang);
    },

    /**
       * Gets translation string through $translate service
       * and handles dynamic parameters replacement
       *
       * @memberOf nabTrans
       * @param {string} transKey - the string to be translated
       * @param {Object} [dynamicParams] (optional) - object containing data that needs to be inserted in translation
       * @param {boolean} [instant=false] -tells method to perform synchronous operation
       * @returns Promise{*} (async) or String (sync)
       */
    translate: function (transKey, dynamicParams, instant) {
      var def;
      if (instant) {
        return $translate.instant(transKey, dynamicParams);
      }

      def = $q.defer();

      $translate(transKey, dynamicParams).then(function (trans) {
        def.resolve(trans);
      }).catch(function () {
        def.reject('Translate failed for: ', transKey);
      });

      return def.promise;
    }
  };
}
