import templateUrl from './multiSelect.html';
import multiSelectItemsModaltemplateUrl from './multiSelectItems/multiSelectItemsModal/multiSelect.html';

(function () {
  angular.module('7Shop.Common')
    .directive('sMultiSelect', sMultiSelect);

  function sMultiSelect(
    $document,
    Modal
  ) {
    return {
      scope: {
        items: '=',
        onItemToggle: '&'
      },
      templateUrl: templateUrl,
      link: function (scope, element) {
        scope.showDropdownList = false;

        scope.toggleItemsDropdown = function () {
          scope.showDropdownList = !scope.showDropdownList;
          if (scope.showDropdownList) {
            $document.on('click.sDropdown', function (event) {
              var isClickedElementChildOfPopup = element
                .find(event.target)
                .length > 0;

              if (!isClickedElementChildOfPopup) {
                scope.$evalAsync(function () {
                  scope.toggleItemsDropdown();
                });
              }
            });
          } else {
            $document.off('click.sDropdown');
          }
        };

        scope.showItemsInModal = function () {
          var self = this;
          Modal.show({
            id: 'multiSelectItems',
            callback: function (modal) {
              modal.scope.items = self.items;
              modal.scope.onItemToggle = self.onItemToggle;
            },
            inputs: {
              showClose: true,
              title: false,
              templateUrl: multiSelectItemsModaltemplateUrl
            }
          });
        };
      }
    };
  }
})();
