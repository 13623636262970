import angular from 'angular';

angular.module('7Shop.Core')
  .provider('LoginData', LoginDataProvider);

function LoginDataProvider() {
  var self = this;

  this.$get = function () {
    return self;
  };
}
