import multiSelectItems from './multiSelectItems.html';

(function () {
  angular.module('7Shop.Common')
    .directive('sMultiSelectItems', sMultiSelectItems);

  function sMultiSelectItems() {
    return {
      scope: {
        items: '=',
        onItemToggle: '&'
      },
      templateUrl: multiSelectItems,
      link: function (scope) {
        scope.toggleSelect = function () {
          this.selected = !this.selected;
          scope.onItemToggle();
        };
      }
    };
  }
})();
