(function () {
  angular.module('7Shop.Core')
    .service('DeviceService', DeviceService);

  /**
     *
     * @constructor DeviceService
     */
  function DeviceService() {
    /**
     * @memberOf DeviceService
     */
    this.init = function () {

    };
  }
})();
