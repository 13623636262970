(function () {
  var checkIsDuplicate = function (checkObj) {
    var duplicate = false;
    var i = 0;
    var j;

    for (i; i <= checkObj.dataToCheckIn.length; i += 1) {
      for (j = i; j <= checkObj.dataToCheckIn.length; j += 1) {
        // eslint-disable-next-line
        if (i != j && checkObj.dataToCheckIn[i] == checkObj.dataToCheckIn[j]) {
          duplicate = true;
        }
      }
    }

    return duplicate;
  };

  angular.module('7Shop.Common')
    .directive('isDuplicate', function isDuplicateDrv() {
      return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
          isDuplicateValues: '='
        },
        link: function (scope, el, attrs, modelCtrl) {
          modelCtrl.$validators.isDuplicate = function () {
            var valid = true;
            // check does number already exist in another input
            if (scope.isDuplicateValues.length > 0) {
              valid = !checkIsDuplicate({
                dataToCheckIn: scope.isDuplicateValues
              });
            }

            return valid;
          };
        }
      };
    });

  angular.module('7Shop.Common')
    .service('isDuplicate', isDuplicate);

  /**
     *
     * @constructor isDuplicate
     */
  function isDuplicate() {
    return {

      /**
             * Check does array has duplicate values
             * @param {Object} checkObj
             * @param {Array} checkObj.dataToCheckIn - list of items to check against
             * @memberOf isDuplicate
             */
      check: function (checkObj) {
        return checkIsDuplicate(checkObj);
      }
    };
  }
})();
