import './app';
import './betshop/betshopService';
import './client-config/sevenClientConfig';
import './client-core/sevenClientCoreSvc';
import './client-core/sevenClientRules';
import './client-core/sevenGameSvc';
import './client-core/sevenGamesMapper';
import './client-core/sevenGamesSvc';
import './company/companyService';
import './directives/isDesktopDirective';
import './directives/isTouchDirective';
import './directives/sEqualsDirective';
import './gravity/gravitySettingsProvider';
import './keyEvents/keyEventService';
import './router/routerConfigurationConstant';
import './services/configService';
import './services/deviceService';
import './services/environmentService';
import './services/localDataConstant';
import './services/loginDataProvider';
import './services/gameService';
import './services/generalModulesService';
import './services/sevenRoutesConstant';
import './services/stateAssemblerProvider';
import './services/switchViewService';
import './services/templateLoaderService';
import './shortcuts/isShortcutActiveFilter';
import './shortcuts/shortcutService';
import './filters/sevenMoneyOverrideFilter';
import './components/sGravityControlComponent';

export default '7Shop.Core';
