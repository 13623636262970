import { isUndefined, find } from 'underscore';
import isMobile from 'ismobilejs';

(function () {
  angular.module('7Shop.Core')
    .provider('ShopEnvironment', ShopEnvironmentProvider);

  function ShopEnvironmentProvider($windowProvider) {
    var self = this;
    var states = {
      touch: false
    };

    const supportedLanguages = {
      en: {
        path: 'en-US'
      },
      bs: {
        path: 'hr'
      },
      de: {
        path: 'de'
      },
      sr: {
        path: 'sr-Latn'
      },
      srLatn: {
        path: 'sr-Latn'
      },
      srCyrl: {
        path: 'sr-Latn'
      },
      sk: {
        path: 'sk'
      },
      ro: {
        path: 'ro'
      },
      hr: {
        path: 'hr'
      },
      es: {
        path: 'es'
      },
      it: {
        path: 'it'
      },
      tr: {
        path: 'tr'
      },
      pl: {
        path: 'pl'
      },
      me: {
        path: 'me'
      },
      el: {
        path: 'el'
      },
      bg: {
        path: 'bg'
      },
      pt: {
        path: 'pt'
      },
      ru: {
        path: 'ru'
      },
      cs: {
        path: 'cs'
      },
      ka: {
        path: 'ka'
      },
      hu: {
        path: 'hu'
      },
      ke: {
        path: 'ke'
      },
      fr: {
        path: 'fr'
      },
      sl: {
        path: 'sl'
      },
      mkLatn: {
        path: 'mk-Latn'
      },
      mkCyrl: {
        path: 'mk-Latn'
      },
      sq: {
        path: 'sq'
      },
      ht: {
        path: 'ht'
      },
      st: {
        path: 'st'
      },
      sw: {
        path: 'sw'
      },
      id: {
        path: 'id'
      },
      vi: {
        path: 'vi'
      },
      zh: {
        path: 'zh'
      },
      th: {
        path: 'th'
      },
      uk: {
        path: 'uk'
      },
      lv: {
        path: 'lv'
      },
      ko: {
        path: 'ko'
      },
      km: {
        path: 'km'
      }
    };
    this.dc = null;
    this.language = null;

    function getUrlParameter(name) {
      var $ngWindow = $windowProvider.$get();
      var regex;
      var results;
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
      regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      results = regex.exec($ngWindow.location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    this.getUrlParameter = getUrlParameter;

    /**
     * @param {String} newDc
     */
    this.setDc = function (newDc) {
      this.dc = newDc;
    };

    /**
     * @return {String | null}
     */
    this.getDc = function () {
      return this.dc;
    };

    this.setLanguage = function (newLang) {
      this.language = newLang;
    };

    this.getLanguage = function () {
      return this.language;
    };

    this.getLocalePath = function () {
      var locale = supportedLanguages[this.language || 'en'];
      if (!locale) { locale = supportedLanguages.en; }

      return locale.path;
    };

    this.setState = function (state, value) {
      states[state] = value;
    };

    this.getState = function (state) {
      return states[state];
    };

    this.isDebugMode = function () {
      // here will be more,
      // for now if we are in nw or chrome app
      // turn off debugging
      var debugModeParam = getUrlParameter('debugMode');
      var debugMode = !isUndefined(debugModeParam) ? debugModeParam : false;

      if ((typeof process !== 'undefined'
          && process.versions['node-webkit'])
        || !debugMode) {
        return false;
      }

      return true;
    };

    this.$get = function (
      $window,
      GravitySettings
    ) {
      this.isInTouchMode = function () {
        var forceTouchScreen = JSON.parse(localStorage.getItem('forceTouch'));
        if (forceTouchScreen) return true;
        return getUrlParameter('deviceType') === 'touch'
        || (GravitySettings.getModuleDataKeyValue('module.touch', 'autoDetect') && isMobile().any)
        || (GravitySettings.getModuleDataKeyValue('module.touch', 'forceTouch'));
      };

      this.isNativeTouch = function () {
        return getUrlParameter('deviceType') === 'touch' || isMobile().any;
      };

      this.shouldRedirect = function (betshopId, deviceId) {
        var availableChannels = GravitySettings.getByKey('channels');
        var channelConfig = GravitySettings.getByKey('channel');
        var channelName = channelConfig.default;
        var overrideChannel = false;
        var foundChannel;

        // don't do any redirects if we are on localhost
        if ($window.location.hostname === 'localhost') {
          return false;
        }

        // it is possible to override default channel per betshop or device
        if (channelConfig.override) {
          overrideChannel = find(channelConfig.override, function (override) {
            return override.id === betshopId || override.id === deviceId;
          });

          if (overrideChannel) {
            channelName = overrideChannel.channel;
          }
        }

        // find channel url
        foundChannel = find(availableChannels.list, function (cha) {
          return cha.id === channelName;
        });

        // if current url is not same as url set in CMS return redirect url
        if (foundChannel.url !== ($window.location.origin + $window.location.pathname).replace(/\/$/, '')) {
          return foundChannel.url;
        }

        return false;
      };

      this.getLogLevel = () => {
        const url = new URL($window.location.href);
        const level = url.searchParams.get('logLevel')
          || localStorage.getItem('settings.logLevel')
          || GravitySettings.getModuleDataKeyValue('application', 'logLevel')
          || 'warn';
        return level;
      };

      angular.extend(this, self);
      return self;
    };
  }
})();
