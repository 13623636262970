import sTabsTemplate from './sTabs.html';

(function () {
  angular.module('7Shop.Common').component('sTabs', {
    transclude: true,
    templateUrl: sTabsTemplate,
    controller: sTabsCtrl,
    bindings: {
      color: '@'
    }
  });

  function sTabsCtrl() {
    this.panes = [];

    this.select = function (pane) {
      angular.forEach(this.panes, function (p) {
        p.selected = false;
      });
      pane.selected = true;
    };
    this.addPane = function (pane) {
      if (this.panes.length === 0) {
        this.select(pane);
      }
      this.panes.push(pane);
    };
  }
})();
