(function () {
  // Supplant
  if (!String.prototype.supplant) {
      String.prototype.supplant = function (o) {
          return this.replace(
              /{([^{}]*)}/g,
              function (a, b) {
                  var r = o[b];
                  return typeof r === 'string' || typeof r === 'number' ? r : a;
              }
          );
      };
  }

  // Object.assign
  if (typeof Object.assign != 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, "assign", {
      value: function assign(target, varArgs) { // .length of function is 2
        'use strict';
        if (target == null) { // TypeError if undefined or null
          throw new TypeError('Cannot convert undefined or null to object');
        }

        var to = Object(target);

        for (var index = 1; index < arguments.length; index++) {
          var nextSource = arguments[index];

          if (nextSource != null) { // Skip over if undefined or null
            for (var nextKey in nextSource) {
              // Avoid bugs when hasOwnProperty is shadowed
              if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                to[nextKey] = nextSource[nextKey];
              }
            }
          }
        }
        return to;
      },
      writable: true,
      configurable: true
    });
  }

  // https://tc39.github.io/ecma262/#sec-array.prototype.find
  if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
      value: function(predicate) {
        // 1. Let O be ? ToObject(this value).
        if (this == null) {
          throw new TypeError('"this" is null or not defined');
        }

        var o = Object(this);

        // 2. Let len be ? ToLength(? Get(O, "length")).
        var len = o.length >>> 0;

        // 3. If IsCallable(predicate) is false, throw a TypeError exception.
        if (typeof predicate !== 'function') {
          throw new TypeError('predicate must be a function');
        }

        // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
        var thisArg = arguments[1];

        // 5. Let k be 0.
        var k = 0;

        // 6. Repeat, while k < len
        while (k < len) {
          // a. Let Pk be ! ToString(k).
          // b. Let kValue be ? Get(O, Pk).
          // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
          // d. If testResult is true, return kValue.
          var kValue = o[k];
          if (predicate.call(thisArg, kValue, k, o)) {
            return kValue;
          }
          // e. Increase k by 1.
          k++;
        }

        // 7. Return undefined.
        return undefined;
      },
    configurable: true,
    writable: true
  });
}
}());

(function($) {
  var splitVersion = $.fn.jquery.split(".");
  var major = parseInt(splitVersion[0]);
  var minor = parseInt(splitVersion[1]);

  var JQ_LT_17 = (major < 1) || (major == 1 && minor < 7);

  function eventsData($el) {
      return JQ_LT_17 ? $el.data('events') : $._data($el[0]).events;
  }

  function moveHandlerToTop($el, eventName, isDelegated) {
      var data = eventsData($el);
      var events = data[eventName];

      if (!JQ_LT_17) {
          var handler = isDelegated ? events.splice(events.delegateCount - 1, 1)[0] : events.pop();
          events.splice(isDelegated ? 0 : (events.delegateCount || 0), 0, handler);

          return;
      }

      if (isDelegated) {
          data.live.unshift(data.live.pop());
      } else {
          events.unshift(events.pop());
      }
  }

  function moveEventHandlers($elems, eventsString, isDelegate) {
      var events = eventsString.split(/\s+/);
      $elems.each(function() {
          for (var i = 0; i < events.length; ++i) {
              var pureEventName = $.trim(events[i]).match(/[^\.]+/i)[0];
              moveHandlerToTop($(this), pureEventName, isDelegate);
          }
      });
  }

  function makeMethod(methodName) {
      $.fn[methodName + 'First'] = function() {
          var args = $.makeArray(arguments);
          var eventsString = args.shift();

          if (eventsString) {
              $.fn[methodName].apply(this, arguments);
              moveEventHandlers(this, eventsString);
          }

          return this;
      }
  }

  // bind
  makeMethod('bind');

  // one
  makeMethod('one');

  // delegate
  $.fn.delegateFirst = function() {
      var args = $.makeArray(arguments);
      var eventsString = args[1];

      if (eventsString) {
          args.splice(0, 2);
          $.fn.delegate.apply(this, arguments);
          moveEventHandlers(this, eventsString, true);
      }

      return this;
  };

  // live
  $.fn.liveFirst = function() {
      var args = $.makeArray(arguments);

      // live = delegate to the document
      args.unshift(this.selector);
      $.fn.delegateFirst.apply($(document), args);

      return this;
  };

  // on (jquery >= 1.7)
  if (!JQ_LT_17) {
      $.fn.onFirst = function(types, selector) {
          var $el = $(this);
          var isDelegated = typeof selector === 'string';

          $.fn.on.apply($el, arguments);

          // events map
          if (typeof types === 'object') {
              for (type in types)
                  if (types.hasOwnProperty(type)) {
                      moveEventHandlers($el, type, isDelegated);
                  }
          } else if (typeof types === 'string') {
              moveEventHandlers($el, types, isDelegated);
          }

          return $el;
      };
  }

})(jQuery);
