(function () {
  /**
     * Focus first input on view. Focus using specific class '.inpt'
     * */
  angular.module('7Shop.Common')
    .directive('focusFirstInput', function focusFirstInput() {
      return {
        restrict: 'A',
        link: function (scope) {
          scope.focusFirstInput = function () {
            angular.element('.inpt').eq(0).trigger('focus');
          };
        }
      };
    });
})();
