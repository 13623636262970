import { each, uniq } from 'underscore';

(function () {
  angular.module('7Shop.Core')
    .service('GameService', GameService);

  /**
   * @class GameService
   * @memberof module."7Shop.Core"
   */
  function GameService(
    $q,
    $rootScope,
    $injector,
    SevenGamesSvc,
    nabTrans,
    nabValidator,
    currency,
    SevenClientConfig,
    nabMessaging,
    GravitySettings,
    ConfigService
  ) {
    var response = {
      notification: {
        message: 'Unknown message',
        type: 'warning',
        delay: 2000,
        focus: false,
        actions: false
      }
    };

    var whiteList = ['LuckySix', 'HorseRaces', 'GreyhoundRaces', 'LuckyX', 'VirtualMotorcycleSpeedway', 'SlotCarRaces'];

    var checkGameActivity = function (activeGame) {
      var activity = true;
      var messageKey = '';

      if (activeGame.gameInfo && !activeGame.gameInfo.isActive) {
        messageKey = 'notifications.game_deactivated';
        activity = false;
      }

      if (activeGame.gameDeactivating) {
        messageKey = 'notifications.game_deactivating';
        activity = false;
      }

      return {
        value: activity,
        messageKey: messageKey
      };
    };

    // listen for unhandled ticketUpdate mesasge from NCM and resolve it,
    // this is case when we have product inside iframe but it is using SCM as messaging infrastructure
    nabMessaging.subscribe('NCM:ticketUpdate', function (e, data) {
      var eventData = data.data;
      nabMessaging.publish('ticket:' + eventData.data.action + 'Response', {
        message: 'ticket:' + eventData.data.action + 'Response',
        ticketData: eventData.data
      });
    });

    $rootScope.$on('SevenClientCore:ready', function () {
      // var megaMenuPriorities = GravityMenus.getMenuAsObjectOfPriorities('megaMenu');
      var failedGames = '';

      if (SevenGamesSvc.failedGames.length) {
        failedGames = '';

        angular.forEach(SevenGamesSvc.failedGames, function (value, key) {
          failedGames += SevenGamesSvc.failedGames.length === (key + 1) ? value.id : value.id + ',';
        });

        $rootScope.$emit('7S:Dialog.ShowModal', {
          message: nabTrans.translate('notifications.config_load_failed', {
            games: failedGames
          }, true),
          type: 'warning',
          delay: 3000,
          focus: false,
          actions: false
        });
      }
    });

    /**
         * Listen for bets update
         *
         * @event 7S:Widget.UpdateProductSettings
         * @type {Object}
         * @property {String} action
         * @property {Object} data
         * @property {Object} data.productId
         * @property {Object} data.shortcuts
         * @property {String} data.color
         * @property {String} data.icon
         * @property {Function} data.ticketCheckFormatter
         *
         */
    $rootScope.$on(
      '7S:Widget.UpdateProductSettings',

      /**
             *
             * @param e
             * @param eventData
             * @param eventData.productId
             * @param eventData.data
             * @param eventData.data.shortcuts
             * @param eventData.data.color
             * @param eventData.data.ticketCheckFormatter
             * @param eventData.data.icon
             */
      function (e, eventData) {
        var dataToUpdate = {};
        each(eventData.data, function (value, key) {
          if (angular.isDefined(value)) {
            dataToUpdate[key] = value;
          }
        });

        SevenGamesSvc.getGame(eventData.productId)
          .updateLocalSettings(dataToUpdate);
      }
    );

    return {
      mergeGravityConfigWithProducts: function (modules) {
        var moduleConfig = {};
        var configLocalOverwrite = {};
        modules.forEach(function (module) {
          if (module.type === 'product') {
            moduleConfig = GravitySettings.getByKey('module.' + module.id);
            // let's give option for DEV to override CMS config,
            // for example DEV wants to set URL to localhost
            configLocalOverwrite = JSON.parse(localStorage.getItem('module.' + module.id));
            if (configLocalOverwrite || moduleConfig) {
              module.local = configLocalOverwrite || moduleConfig;
              if (configLocalOverwrite) {
                // eslint-disable-next-line
                      console.info('Product config loaded from local storage', module.id, configLocalOverwrite);
              }
            }
          }
        });
      },

      getActiveGamesModules: function (modules) {
        var result = [];

        modules.forEach(function (module) {
          if (module.type === 'product') {
            if (module.local.modules) {
              // Array.prototype.push.apply(result, module.local.modules);
              result.push(module.local.modules[0]);
            }
          }
        });

        return uniq(result);
      },

      /**
       * Try sending bet to bet slip
       *
       * @memberof module."7Shop.Core".GameService
       * @param {Array} bets
       * @param editMode
       * @returns {*}
       */
      sendToBetslip: function (bets, editMode) {
        var BetslipService = $injector.get('BetslipService');
        var deferred = $q.defer();

        this.validatePreBetslip(bets, editMode, BetslipService.ticket.config.ticketType.id).then(
          function () {
            var stake = whiteList.indexOf(SevenGamesSvc.activeGame.clientId) >= 0
              ? false : BetslipService.ticket.stake;
              // let's see is bet focused
            var focusedBet = BetslipService.ticket.betsCollection.getFirstBy(
              bets[0].uid,
              'uid'
            );

            // user clicked on bet on betslip
            if (focusedBet && focusedBet.focus) {
              $rootScope.$emit('7S:Betslip.UpdateSelectedBet', {
                data: {
                  bets: [bets[0]],
                  stake: stake
                }
              });
            } else {
              $rootScope.$broadcast('7S:Betslip.AddBet', {
                data: {
                  bets: bets,
                  stake: stake
                }
              });
            }
            deferred.resolve();
          },
          function () {
            deferred.reject(response);
          }
        );

        return deferred.promise;
      },

      validatePreBetslip: function (bets, editMode, ticketType) {
        var deferred = $q.defer();
        var BetslipService = $injector.get('BetslipService');
        var activeGame = SevenGamesSvc.activeGame;
        var gameState = { value: true };
        var activeBetsLength;
        var gameService;
        var gameRules;
        var TICKET_TYPES;
        var validationErrors;
        var errorName;
        var transKey;

        gameState = checkGameActivity(activeGame);

        if (!gameState.value) {
          response.notification.message = nabTrans.translate(
            gameState.messageKey,
            {
              game: nabTrans.translate('games.' + activeGame.id.toLowerCase(), {}, true)
            },
            true
          );

          deferred.reject();
        }

        // in edit mode validation would fail because if max bet is 2, he thinks that we are adding third bet
        activeBetsLength = editMode ? BetslipService.ticket.bets.length : BetslipService.ticket.bets.length + bets.length;

        gameService = SevenGamesSvc.getGame(SevenGamesSvc.activeGame.id);
        gameRules = gameService.getRulesSvc();
        TICKET_TYPES = SevenClientConfig.getSettings().global.ticketTypes;

        validationErrors = nabValidator.validate(
          // get list of rules to validate
          gameRules.preBetSlip({
            activeBetsLength: activeBetsLength,
            ticketType: ticketType
          }),
          // parameters
          {
            bets: bets,
            betCount: activeBetsLength,
            ticketType: ticketType || TICKET_TYPES.SINGLE.id,
            currency: currency.value,
            money: {
              currencyDisplayValue: currency.value,
              cashStrategy: ConfigService.getCashStrategy()
            }
          },
          // clear any previous errors
          true
        );

        if (validationErrors && validationErrors.length) {
          errorName = validationErrors[0].code;
          transKey = validationErrors[0].transKey + errorName;

          response.notification.message = nabTrans.translate(transKey, validationErrors[0].transRef, true);
          deferred.reject(response);
        } else {
          deferred.resolve();
        }

        return deferred.promise;
      }
    };
  }
})();
