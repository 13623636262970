(function () {
  angular.module('7Shop.Common').directive('numberDelimiter', function numberDelimiter($filter, $locale) {
    return {
      require: 'ngModel',
      link: function (scope, element, attrs, modelCtrl) {
        var numOfDecimalPlaces = 2;
        var decimalSeparator = $locale.NUMBER_FORMATS.DECIMAL_SEP;
        var groupSeparator = $locale.NUMBER_FORMATS.GROUP_SEP;

        function replaceExceptFirst(str) {
          // replace all ocurrences of characters except first one
          var index = str.indexOf('.');
          return str.substring(0, index + 1)
              + str.substring(index + 1).replace(/\./g, '');
        }

        function convertToValidNumber(inputValue) {
          var expression = '\\' + groupSeparator;
          var regex;
          inputValue += '';
          if (groupSeparator !== '') { // to prevent regex error
            regex = new RegExp(expression, 'g');
            inputValue = inputValue.replace(regex, ''); // remove group separator
          }
          inputValue = inputValue.replace(decimalSeparator, '.'); // replace decimal separator with '.'
          inputValue = replaceExceptFirst(inputValue);
          inputValue = inputValue.replace(/[^0-9.]/g, ''); // remove everything that is not number or '.'
          inputValue = (inputValue * 100) / 100;
          inputValue = Number((inputValue).toFixed(numOfDecimalPlaces));
          return inputValue;
        }

        function validateDecimalPlaces(inputValue) {
          var temp;
          var countOfNumbersAfterDecimalDelimiter;
          inputValue += '';
          temp = inputValue.split(decimalSeparator);
          if (temp && temp[1]) {
            countOfNumbersAfterDecimalDelimiter = temp[1].length;
            if (countOfNumbersAfterDecimalDelimiter > numOfDecimalPlaces) {
              // inputValue = inputValue.substring(0, inputValue.length - 1);
              inputValue = inputValue.substring(0, inputValue.length - 1);
            }
          }

          return inputValue;
        }

        function getGeneratedDecimalPart() {
          var generatedDecimalPart = decimalSeparator;
          var i = 0;

          for (i; i < numOfDecimalPlaces; i += 1) { generatedDecimalPart += '0'; }

          return generatedDecimalPart;
        }

        function replaceLast(inputValue, find, replace) {
          var index = inputValue.lastIndexOf(find);

          if (index > 0) {
            return inputValue.substring(0, index) + replace + inputValue.substring(index + find.length);
          }

          return inputValue;
        }

        // formats input
        function getFormattedInput(inputValue) {
          var transformedInput;
          var lastChar;
          var decimalDelimiterCount;
          var isDecimalDelimiterNextToLast = false;
          var isZero;
          var decimalPart;
          var generatedDecimalPart = getGeneratedDecimalPart();
          inputValue += '';

          if (inputValue[inputValue.length - 1] === groupSeparator) { // when operator enters . on numpad, depending on keyboard lang that is interpreted as ,
            inputValue = replaceLast(inputValue, groupSeparator, decimalSeparator);
          }

          decimalPart = inputValue.substring(inputValue.length - numOfDecimalPlaces - 1);
          decimalDelimiterCount = inputValue.split(decimalSeparator).length - 1;// how many times decimal delimiter occurs in string
          isZero = (inputValue[inputValue.length - 1] === '0') && (inputValue.indexOf(decimalSeparator) >= 0);// if user puts zero after decimal delimiter
          if (decimalDelimiterCount === 1 && isZero) {
            isDecimalDelimiterNextToLast = inputValue[inputValue.length - numOfDecimalPlaces] === decimalSeparator;
          }

          // check if last char is . or , (decimal separator) because filter removes them.
          // But do that only in case there is only one ',' in string.
          // If there are more ',' remove them
          if (decimalDelimiterCount === 1 && inputValue[inputValue.length - 1] === decimalSeparator) {
            lastChar = inputValue[inputValue.length - 1];
          }

          inputValue = convertToValidNumber(inputValue);

          transformedInput = $filter('number')(inputValue);

          if (lastChar && lastChar !== groupSeparator) {
            transformedInput += lastChar;
          } else if (isZero && isDecimalDelimiterNextToLast) {
            transformedInput += decimalSeparator + '0';
          } else if (decimalPart === generatedDecimalPart) {
            transformedInput += generatedDecimalPart;
          } else if (isZero) {
            transformedInput += '0';
          }

          return transformedInput;
        }

        function parseViewValue(inputValue) {
          var formattedValue;
          if (inputValue === '') { // this is neccessary because of 0,00
            modelCtrl.$viewValue = '';
            modelCtrl.$render();
            return 0;
          }
          inputValue = validateDecimalPlaces(inputValue);
          formattedValue = getFormattedInput(inputValue);// formats input
          inputValue = convertToValidNumber(inputValue);

          modelCtrl.$setViewValue(formattedValue);
          modelCtrl.$render();
          // Return what we want the model value to be
          if (angular.isNumber(inputValue)) { return inputValue; }
          return Number(inputValue);
        }

        function formatModelValue(inputValue) {
          var formattedValue;
          if (!inputValue) { return; }
          inputValue = validateDecimalPlaces(inputValue);
          formattedValue = $filter('number')(inputValue, numOfDecimalPlaces);// formats input
          inputValue = convertToValidNumber(inputValue);
          // eslint-disable-next-line consistent-return
          return formattedValue;
        }

        modelCtrl.$parsers.push(parseViewValue);
        modelCtrl.$formatters.push(formatModelValue);
      }
    };
  });
})();
