import sGravityControlHtml from './sGravityControl.html';

/**
* @class sGravityControlController
* @memberof module:"7Shop.Core"
*/
/* @ngInject */
function sGravityControlController($filter) {
  var ctrl = this;
  ctrl.dropdownMethods = [];

  ctrl.$onInit = function () {
    generateMethods();
  };

  function generateMethods() {
    var keyNames;
    if (ctrl.dropdownData) {
      keyNames = Object.keys(ctrl.dropdownData);
      if (keyNames) {
        keyNames.forEach((element) => {
          ctrl['dropdownSelect' + element] = function (data) {
            ctrl.dropdownData[element].selectedValue = $filter('filter')(
              ctrl.dropdownData[element].selectData, { value: data }, true
            )[0];
          };
        });
      }
    }
  }
}

export default {
  templateUrl: sGravityControlHtml,
  controller: sGravityControlController,
  bindings: {
    field: '<',
    formViewModel: '<',
    dropdownData: '<',
    form: '<',
    touch: '<'
  }
};
