angular.module('7Shop.Core').value('SevenClientRules', {
  rules: [
    {
      conditions: [
        {
          dataType: 'numOfSystems',
          ruleType: 'GTE',
          value: 1
        }
      ],
      idRule: 'minNumberOfSystemBets',
      message: 'Min number of systems',
      name: 'minNumberOfSystemBets',
      ticketType: null
    },

    {
      conditions: [
        {
          dataType: 'betExists',
          ruleType: 'BO',
          value: true
        }
      ],
      idRule: 'betExistance',
      message: 'Bet does not exist',
      name: 'betExistance',
      ticketType: '1'
    },

    {
      conditions: [
        {
          dataType: 'bettingStatus',
          ruleType: 'BO',
          value: true
        }
      ],
      idRule: 'betUnlocked',
      message: 'Match is locked for betting',
      name: 'betUnlocked',
      ticketType: '1'
    }
  ]
});
