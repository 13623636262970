(function () {
  angular.module('7Shop.Common')
    .directive('sContextMenu', sContextMenu);

  function sContextMenu($parse, $rootScope, $exceptionHandler) {
    return {
      restrict: 'A',
      compile: function ($element, attr) {
        var fn = $parse(attr.sContextMenu);
        return function (scope, element) {
          element.on('contextmenu', function (event) {
            var callback = function () {
              fn(scope, { $event: event });
            };

            event.preventDefault();

            if (!$rootScope.$$phase) {
              scope.$apply(callback);
            } else {
              try {
                callback();
              } catch (error) {
                $exceptionHandler(error);
              }
            }
          });
        };
      }
    };
  }
})();
