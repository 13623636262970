/* eslint-disable consistent-return */
import templateUrl from './popup.html';

// Show/Hide popup template
(function () {
  angular.module('7Shop.Common')
    .directive('popUp', function popUpDir(
      $rootScope,
      $timeout,
      popupService,
      SevenClientLibConfig
    ) {
      return {
        transclude: true,
        replace: true,
        scope: {
          onClose: '&',
          popupClass: '@',
          popupRunInit: '=?',
          popupCloseDisabled: '=?'
        },
        templateUrl: function () {
          return SevenClientLibConfig.popup.templateUrl
                            || templateUrl;
        },
        link: function (scope, el, attr) {
          // popup close setting
          scope.popupCloseDisabled = !!scope.popupCloseDisabled;

          // show popup immediately after registration
          scope.popupRunInit = !!scope.popupRunInit;

          scope.isTranscludedContent = !angular.isDefined(attr.popupContent);

          scope.popupSize = angular.isDefined(attr.popupSize) ? attr.popupSize : 'normal';

          popupService.addPopup({ scope: scope, el: el, attr: attr });

          scope.popupId = attr.popupId;

          // name of header in wrapper
          scope.popupName = attr.popupName;

          // name of included file
          scope.route = attr.popupContent;

          // show header by default
          scope.popupHideHeader = !!angular.isDefined(attr.popupHideHeader);

          // stop propagation on content click
          // ro prevent firing overlay close
          el.find('.s-popup__container').on('click', function (e) {
            e.stopPropagation();
          });

          /**
                     * Close popup
                     * @param {Object} config
                     * @param {Boolean} config.skipCb - if set to true we'll skip callback on close
                     */
          scope.closePopup = function (config) {
            config = config || {};

            if (scope.popupCloseDisabled && !config.forceClose) {
              return false;
            }

            el.css({
              display: 'none'
            });

            $rootScope.$broadcast('popUp:close:' + scope.popupId, config);
          };

          scope.showPopup = function (config) {
            if (config && config.popupCloseDisabled) {
              scope.popupCloseDisabled = config.popupCloseDisabled;
            }

            el.css({
              display: 'block'
            });

            $rootScope.$broadcast('popUp:show:' + scope.popupId);
          };

          // close on init
          if (!scope.popupRunInit) {
            scope.closePopup();
          }
        }
      };
    });
})();
