/* eslint-disable no-prototype-builtins */
/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
(function () {
  var TICKET_TYPES = {
    SINGLE: {
      id: 3,
      name: 'single'
    },
    COMBO: {
      id: 1,
      name: 'combo'
    },
    SYSTEM: {
      id: 2,
      name: 'system'
    }
  };

  /**
     * The complete Shortcut, or one or more components of the Triforce.
     * @typedef {Object} SevenShortcut
     * @property {string} shortcut - friendly name representing key on keyboard
     * @property {string} param
     * @property {string} name - Translated, human readable, description of the shortcut
     * @property {boolean} active - activate/deactivate shortcut
     * @property {Array.<number>} keyCodes - key codes to be used to trigger this shortcut
     * @property {string} tc_key - translation key
     */

  angular.module('7Shop.Core')
    .provider('SevenClientConfig', SevenClientConfigProvider);

  /**
     *
     * @constructor SevenClientConfigProvider
     */
  function SevenClientConfigProvider() {
    var settings = {
      global: {
        language: 'en',
        deliveryPlatform: 'Retail', // Retail, Terminal, TerminalAdmin
        apiPoint: 'https://services-staging.7platform.com/shop',
        ticketTypes: TICKET_TYPES
      },
      device: {
        uuid: ''
      },
      ncm: {
        optimized: false,
        token: '',
        username: '',
        channels: null,
        additionalChannels: [],
        useScmPingPong: true,
        connectionCheckerUrl: 'http://localhost:5051/get_status'
      },
      localScm: {
        active: false,
        url: 'http://localhost:8070',
        publishUrl: 'http://localhost:8071/publish',
        setStateUrl: 'http://localhost:8071/setState'
      },
      cardReader: {
        active: false,
        url: 'http://localhost:8077'
      },
      modules: {
        games: {
          shortcutPrefix: 'F',
          LuckySix: {},
          LiveBetting: {},
          PreMatchBetting: {},
          GreyhoundRaces: {},
          VirtualGreyhoundRaces: {},
          HorseRaces: {},
          VirtualHorseRaces: {},
          VirtualMotorcycleSpeedway: {},
          SlotCarRaces: {},
          SportradarVTO: {},
          SportradarVFL: {},
          SportradarVBL: {},
          LuckyX: {},
          SportsbookSM: {},
          SportradarVirtualFootball: {}
        },
        general: {
          shortcutPrefix: 'ALT+'
        }
      }
    };

    function updateSettings(newSettings) {
      angular.merge(settings, newSettings);
    }

    function getSettings() {
      return settings;
    }

    /**
         *
         * @constructor SevenClientConfig
         */
    function SevenClientConfig() {
      return {

        /**
                 * @memberOf SevenClientConfig
                 */
        updateSettings: updateSettings,

        /**
                 * @memberOf SevenClientConfig
                 */
        getSettings: getSettings,

        /**
                 *
                 * @memberOf SevenClientConfig
                 * @param {String} product - product id
                 * @param {Object} config
                 * @param {Object.<string, SevenShortcut>} config.shortcuts
                 * @param {Function} config.ticketCheckFormatter
                 * @deprecated In favor of  SevenGameSvc.updateLocalSettings
                 */
        updateProductSettings: function (product, config) {
          var gameSettings = this.getSettings().modules.games;

          if (!gameSettings.hasOwnProperty(product)) {
            gameSettings[product] = {};
          }

          angular.merge(gameSettings[product], config);
        },

        /**
                 *
                 * @memberOf SevenClientConfig
                 * @param productId
                 * @return {*}
                 */
        getProductSettings: function (productId) {
          var gameSettings = this.getSettings().modules.games;

          if (!gameSettings[productId]) {
            return false;
          }

          return gameSettings[productId];
        }
      };
    }

    return {

      $get: function () {
        return SevenClientConfig();
      },

      /**
             * Set settings in config phase
             *
             * @memberOf SevenClientConfigProvider
             * @param {Object} newSettings
             * @returns {*}
             */
      set: updateSettings,

      /**
             * @memberOf SevenClientConfigProvider
             */
      getSettings: getSettings,

      /**
             * Add additional channels in config phase
             *
             * @memberOf SevenClientConfigProvider
             * @param {Object []} channels
             * @param {String} channels[].id
             * @param {String} channels[].name
             */
      addAdditionalChannels: function (channels) {
        for (var i = 0; i < channels.length; i++) {
          var channel = channels[i];
          settings.ncm.additionalChannels.push(channel);
        }
      }
    };
  }
})();
