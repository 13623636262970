/* eslint-disable no-cond-assign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable angular/timeout-service */
/* eslint-disable angular/window-service */
/* eslint-disable no-lonely-if */
/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
/* eslint-disable no-undef */
/* eslint-disable angular/document-service */
(function (window, XHR) {
  var config = {
    blackList: [],
    preventActions: true,
    safeTimerDelay: 4000
  };
  var loaderRunning = false;
  var canBeStopped = false;
  var safeTimer = null;
  var d = window.document;
  var pendingRequests = 0;
  var stopListenerAttached = false;
  forceLoader = false;

  function attachXhrInterceptor() {
    var open = XHR.prototype.open;
    var send = XHR.prototype.send;

    function processAction(reqUrl, action) {
      var blackList = config.blackList;
      var blackListed = false; // grab all pending requests which are not blacklisted

      for (var i = 0; i < blackList.length; i++) {
        var urlBlack = blackList[i];
        var reg = new RegExp('\\W*' + urlBlack + '\\W*', 'gi');
        if (reg.test(reqUrl)) {
          blackListed = true;
          break;
        }
      }

      if (!blackListed) {
        if (action === 'start') {
          pendingRequests++;
        } else {
          // Safe check against going into nowhere.
          // If internet is really slow, service will trigger safe timer before
          // request is returned back. Safe timer will reset pendingRequests to 0 and
          // late response will set pendingRequests to -1 which is unreal state.
          if (pendingRequests > 0) pendingRequests--;
        }

        toggleLoader();
      }
    }

    XHR.prototype.open = function (method, url, async, user, pass) {
      this._url = url;
      processAction(url, 'start');
      // treat undefined as true or we will have sync http req
      async = !!(typeof async === 'undefined' || async);
      open.call(this, method, url, async, user, pass);
    };

    XHR.prototype.send = function (data) {
      var self = this;
      var oldOnReadyStateChange;
      var url = this._url;

      function onReadyStateChange() {
        // eslint-disable-next-line no-constant-condition
        if (self.readyState = 4 /* complete */) {
          processAction(url);
        }

        if (oldOnReadyStateChange) {
          oldOnReadyStateChange();
        }
      }

      if (this.addEventListener) {
        this.addEventListener('readystatechange', onReadyStateChange, false);
      } else {
        oldOnReadyStateChange = this.onreadystatechange;
        this.onreadystatechange = onReadyStateChange;
      }

      send.call(this, data);
    };
  }

  function renderTemplate() {
    var title = config.title ? config.title : '';
    return "<div id='loader-container'>"
            + '<h1>' + title + '</h1>'
            + "<div id='loader' class='loader'></div>"
            + '</div>';
  }

  function addDom() {
    var template = renderTemplate();

    if (!(d.getElementById('overlay'))) {
      var temp = d.createElement('div');
      temp.setAttribute('id', 'overlay');
      temp.innerHTML = template;
      d.body.appendChild(temp);
    }
  }

  function toggleLoader() {
    if (pendingRequests >= 1) {
      showLoader();
    } else if (!forceLoader) {
      // end loader if force flag is not set to true
      // and there are no pendingRequests
      endLoader();
    }
  }

  /**
     * Show loader
     *
     * @param {Object} params
     * @param {Number} params.safeTimerDelay
     * @param {Boolean} params.forceLoader - force running loader until it's stoped with endLoader
     */
  function showLoader(params) {
    params = params || {};

    if (params.forceLoader === true) {
      forceLoader = params.forceLoader;
    }

    // don't send multiple times start if loader is already running
    // or if somone already forced show loader
    if (loaderRunning && !forceLoader) {
      return;
    }

    if (config.preventActions && !stopListenerAttached) {
      // prevent any clicks and keydown while loader is running
      d.body.addEventListener('click', stopEvent, true);
      d.body.addEventListener('keydown', stopEvent, true);
      stopListenerAttached = true;
    }

    d.getElementById('overlay').style.display = 'block';
    canBeStopped = false;
    loaderRunning = true;

    // clear previous timer
    if (safeTimer) {
      window.clearTimeout(safeTimer);
      safeTimer = null;
    }

    // Every time loader starts keep timer for
    // turning off loader after x sec.
    // This is just a safe prevention for running loader forever
    // Check after 4 sec by default
    safeTimer = window.setTimeout(function () {
      canBeStopped = false;
      loaderRunning = true;
      endLoader();
    }, params.safeTimerDelay || config.safeTimerDelay);
  }

  /**
     * End loader
     *
     * @param {Object} [params]
     * @param {Boolean} [params.forceLoader]
     */
  function endLoader(params) {
    params = params || {};

    // if you forced loader and you want to stop if you must send forceLoader:false
    if (params.forceLoader === false) {
      forceLoader = params.forceLoader;
    }

    // if loader is not running why are you sending stop?
    // or if someone forced loader than we won't end it
    if (!loaderRunning || forceLoader) {
      return;
    }

    canBeStopped = true;
    loaderRunning = false;

    // Although loader is still running
    // we'll give a chance to postpone stopping
    // because another request could be fired immediately
    // after last one causing flickering
    window.setTimeout(function () {
      if (canBeStopped) {
        window.clearTimeout(safeTimer);
        safeTimer = null;
        d.getElementById('overlay').style.display = 'none';
        loaderRunning = false;
        canBeStopped = false;
        pendingRequests = 0;
        // allow clicks and keydown after we are stopping loader
        if (config.preventActions) {
          d.body.removeEventListener('click', stopEvent, true);
          d.body.removeEventListener('keydown', stopEvent, true);
          stopListenerAttached = false;
        }
      }
    }, 500);
  }

  function stopEvent(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  d.addEventListener('DOMContentLoaded', function () {
    addDom();
    attachXhrInterceptor();
  });

  window._7Loader = {
    setConfig: function (c) {
      // polyfill for Object.assign
      for (var key in c) {
        config[key] = c[key];
      }
    },
    showLoader: showLoader,
    endLoader: endLoader
  };
})(window, XMLHttpRequest);
