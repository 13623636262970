import SHOP_MODULES from './bootstrap/modules';

/**
 *
 * @class Modules
 * @memberOf module."7Shop.Modules"
 */
/* @ngInject */
function Modules($q, $exceptionHandler, $ocLazyLoad, $injector) {
  /**
   *
   * @memberOf module."7Shop.Modules".Modules
   * @type {{}}
   */
  this.gravityModules = {};

  /**
   * @memberOf module."7Shop.Modules".Modules
   * @param {String} moduleName
   * @return {*|boolean}
   */
  this.isModuleEnabled = function (moduleName) {
    var modules = this.gravityModules;
    return modules && modules[moduleName] && modules[moduleName].enabled === true;
  };

  /**
   * @memberOf module."7Shop.Modules".Modules
   * @param {String} moduleName
   * @return {*|boolean}
   */
  this.isModuleLoaded = function (moduleName) {
    return $ocLazyLoad.isLoaded(moduleName);
  };

  /**
   * @memberOf module."7Shop.Modules".Modules
   * @param {*} gravityModules
   */
  this.setData = function (gravityModules) {
    this.gravityModules = gravityModules;
  };

  /**
   * @memberOf module."7Shop.Modules".Modules
   * @see https://github.com/ocombe/ocLazyLoad/issues/89#issuecomment-343164570
   */
  this.loadModule = function (moduleId) {
    var module = SHOP_MODULES.getModule(moduleId);
    // run functions before
    var before = angular.extend([], angular.module('ng')._runBlocks);

    if (!module || !module.lazyLoad) {
      return $q.resolve();
    }

    return module.lazyLoad.load().then(function (mod) {
      // run functions after lazy load
      var after = angular.module('ng')._runBlocks;

      angular.forEach(after, function (fn) {
        if (before.indexOf(fn) === -1) {
          if (fn.length && fn[0] !== '$templateCache') {
            $exceptionHandler('[Sportsbook.Modules] Invoking run block that is not an $templateCache', fn.toString());
          }
          $injector.invoke(fn);
        }
      });
      return $ocLazyLoad.inject(mod.default || module.ng.moduleName);
    });
  };

  /**
   * @memberOf module."7Shop.Modules".Modules
   */
  this.getLoader = function () {
    return $ocLazyLoad;
  };

  /**
   * @memberOf module."7Shop.Modules".Modules
   * @param {*} position
   * @param {*} service
   */
  this.loadModulesByPosition = function (position, service) {
    // lazyLoad modules, position: afterGravityBootstrap
    var modulesToLoad = SHOP_MODULES.filterLazyLoadModules(position);
    var modulePromises = [];
    // go thought each locally defined module
    modulesToLoad.forEach(function (module) {
      var gravityModule = service.getModuleDataKeyValue('modules', module.gravityName);
      if (gravityModule && gravityModule.enabled === true) {
        modulePromises.push(this.loadModule(module.id));
      }
    }.bind(this));

    return $q.all(modulesToLoad);
  };

  /**
   * @memberOf module."7Shop.Modules".Modules
   * @param {*} position
   */
  this.getModulesByPosition = function (position) {
    return SHOP_MODULES.filterLazyLoadModules(position);
  };

  /**
   * @memberOf module."7Shop.Modules".Modules
   * @param {*} id
   */
  this.getModuleDefinition = function (id) {
    return SHOP_MODULES.getModule(id);
  };

  /**
   * @memberOf module."7Shop.Modules".Modules
   */
  this.getModuleDefinitions = function () {
    return SHOP_MODULES;
  };
}

export default Modules;
