(function () {
  angular
    .module('7Shop.Bootstrap')
    .factory('gravityInterceptor', gravityInterceptor);

  function gravityInterceptor() {
    // var isValidUrl = function (uri) {
    // var url = uri.toString();
    // var urlLength = url.length;
    // var baseUrl = CloudflareService.generateCloudflareUrl(NabENV.api.gravity.url);
    // var baseUrlLength;

    // if (url.indexOf('7platform.info/v1/shop/bootstrap') !== -1) { // in moment when bootstrap request is emitted, GravitySettings are not loaded yet so we have to handle this route separately
    //   baseUrl = NabENV.api.gravity.url.replace('7platform.com', '7platform.info');
    // }

    // baseUrlLength = baseUrl.length;

    // return (
    //   urlLength >= baseUrlLength
    //   && url.substring(0, baseUrlLength) === baseUrl
    // );
    // };

    return {
      request: function (request) {
        // var LoginData;
        // var userService = $injector.get('userService');
        // var dc = ShopEnvironment.getDc();
        // var lang = ShopEnvironment.getLanguage();

        // if (isValidUrl(request.url)) {
        //   request.headers['X-Nsft-SCD-Dp'] = 'Retail';

        //   if (!request.headers['X-Nsft-SCD-Version']) {
        //     request.headers['X-Nsft-SCD-Version'] = '4';
        //   }

        //   if (dc) {
        //     request.headers['X-Nsft-SCD-DC'] = dc;
        //   }

        //   if (lang) {
        //     request.headers['X-Nsft-SCD-Locale'] = lang;
        //   }

        //   if (Object.keys(LocalData).length) {
        //     request.headers['X-Nsft-SCD-Company-Uuid'] = LocalData.betshop.companyUuid;
        //     request.headers['X-Nsft-SCD-Company-Id'] = LocalData.betshop.companyId;
        //     request.headers['X-Nsft-SCD-Betshop-Id'] = LocalData.betshop.betshopId;
        //     request.headers['X-Nsft-SCD-Device-Id'] = LocalData.device.uuid;
        //     request.headers['X-Nsft-SCD-Device-Uuid'] = LocalData.device.uuid;
        //     request.headers['X-Nsft-SCD-Device-Token'] = LocalData.device.token;
        //     request.headers['X-Nsft-SCD-Account-Id'] = LocalData.betshop.companyUuid;
        //   }

        //   if (userService.state.loggedIn) {
        //     LoginData = userService.getData();
        //     request.headers['X-Nsft-SCD-Locale'] = LoginData.user.language || 'en';
        //     request.headers['X-Nsft-SCD-DC'] = LoginData.company.datacenter || '';
        //   }
        // }

        return request;
      }
    };
  }
})();
