import templateUrl from './galeryTemplate.html';

(function () {
  angular.module('7Shop.Common')
    .directive('sImgGallery', function sImgGallery($rootScope) {
      return {
        templateUrl: templateUrl,
        scope: {
          images: '='
        },
        link: function (scope) {
          scope.lightbox = {
            visible: false,
            imgSrc: null
          };

          scope.showImage = function (img) {
            scope.lightbox = {
              visible: true,
              imgSrc: img
            };
          };

          scope.closeLightbox = function () {
            scope.lightbox.visible = false;
            scope.lightbox.imgSrc = null;
          };

          $rootScope.$on('KeyPressed', function (e, key) {
            if (key === 'esc') {
              scope.closeLightbox();
            }
          });
        }
      };
    });
})();
