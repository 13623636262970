/* eslint-disable vars-on-top */
(function () {
  angular.module('7Shop.Common')
    .directive('sclLoadImage', function sclLoadImageDir(
      $q
    ) {
      return {
        restrict: 'A',
        scope: {
          imgSrc: '@',
          errImgSrc: '@?'
        },
        link: function (scope, el) {
          function loadImg(src) {
            var img = new Image();
            var defer = $q.defer();

            img.src = src;

            img.onload = function () {
              if (el.is('img')) {
                el.src = img.src;
              } else {
                var width = img.naturalWidth;
                el.css({
                  'background-image': 'url("' + img.src + '")',
                  width: width + 'px'
                });
              }
              defer.resolve();
            };

            img.onerror = function () {
              defer.reject();
            };

            return defer.promise;
          }

          loadImg(scope.imgSrc).catch(function () {
            if (scope.errImgSrc) {
              loadImg(scope.errImgSrc).catch(angular.noop);
            }
          });
        }
      };
    });
})();
