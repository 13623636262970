import overlayNotificationTemplate from './overlayNotificationTemplate.html';

(function () {
  angular.module('7Shop.Common')
    .directive('overlayNotification', function overlayNotification() {
      return {
        compile: function (tElement, tAttrs) {
          return function (scope) {
            scope.message = tAttrs.notificationMsg;
          };
        },
        templateUrl: overlayNotificationTemplate
      };
    });
})();
