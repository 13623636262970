/* eslint-disable angular/timeout-service */
/* eslint-disable vars-on-top */
/* eslint-disable consistent-return */
/* eslint-disable radix */
(function () {
  angular.module('7Shop.Common')
    .directive('valueCheck', function valueCheckDir() {
      return {
        restrict: 'A',
        scope: {
          observed: '=',
          observedHasChanged: '=?',
          // duration in milliseconds to keep indicator shown,
          // if 0 keep it for infinite time
          duration: '@'
        },
        controller: function ($scope, $element) {
          var activeTimeout = null;
          var duration = angular.isDefined($scope.duration) ? parseInt($scope.duration) : 5000;

          function resetIndicator() {
            $element[0].classList.remove('value-changed');
            $element[0].classList.remove('value-raised');
            $element[0].classList.remove('value-lowered');
          }

          $scope.$watch('observed', function (newValue, oldValue) {
            if (oldValue === newValue) {
              return false;
            }
            var observedValue;

            if (typeof newValue === 'string') {
              observedValue = newValue;
            } else if (typeof newValue === 'number') {
              observedValue = parseFloat(newValue) > parseFloat(oldValue) ? 'INCREASED' : 'DECREASED';
            }

            resetIndicator();

            if ($scope.observedHasChanged === null) {
              // Indicator was resetted
              return false;
            }

            if (observedValue === 'INCREASED') {
              $element[0].classList.add('value-changed');
              $element[0].classList.add('value-raised');
            } else if (observedValue === 'DECREASED') {
              $element[0].classList.add('value-changed');
              $element[0].classList.add('value-lowered');
            }

            if (activeTimeout) {
              clearTimeout(activeTimeout);
            }

            if (duration) {
              activeTimeout = setTimeout(function () {
                resetIndicator();
                activeTimeout = null;
              }, duration);
            }
          }, true);
        }
      };
    });
})();
