import multiSelectInputTouchDefault from './multiSelectInputTouchDefault.html';
import multiSelectInput from './multiSelectInput.html';

(function () {
  angular.module('7Shop.Common')
    .directive('sMultiSelectInput', sMultiSelectInput);

  function sMultiSelectInput(Touch) {
    return {
      templateUrl: function () {
        return Touch.isTouch()
          ? multiSelectInputTouchDefault
          : multiSelectInput;
      },
      scope: {
        items: '=',
        onItemToggle: '&',
        toggleItemsDropdown: '&',
        showItemsInModal: '&'
      },
      link: function (scope) {
        scope.toggleSelect = function (item) {
          item.selected = !item.selected;
          scope.onItemToggle();
        };
      }
    };
  }
})();
