import { defaults, omit } from 'underscore';

(function () {
  angular.module('7Shop.Core')
    .provider('StateAssembler', StateAssemblerProvider);

  function StateAssemblerProvider(TouchProvider) {
    var self = this;

    this.$get = function () {
      return self;
    };

    this.getStateDefinitions = function (moduleStates) {
      var modules = [];
      var isTouchMode = TouchProvider.isTouch('touch');

      if (moduleStates) {
        angular.forEach(moduleStates, function (state, key) {
          var stateDefinition = {
            name: key
          };
          var templateUrl;
          var templateVersion;
          // Set state definition
          defaults(stateDefinition, state);
          // Omit properties which will be assembled later
          stateDefinition = omit(stateDefinition, 'template', 'templateUrl');
          // Dynamic template
          if (angular.isObject(state.template)) {
            templateVersion = (isTouchMode ? state.template.mobile : state.template.default) || state.template.default;
            stateDefinition.template = templateVersion;
          } else if (state.template) {
            stateDefinition.template = state.template;
          }
          // Dynamic template url
          if (angular.isObject(state.templateUrl)) {
            templateUrl = (isTouchMode ? state.templateUrl.mobile : state.templateUrl.default) || state.templateUrl.default;
            stateDefinition.templateUrl = templateUrl;
          } else if (stateDefinition.templateUrl) {
            stateDefinition.templateUrl = state.templateUrl;
          }
          modules.push(stateDefinition);
        });
        return modules;
      }
      return false;
    };
  }
})();
