/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
(function () {
  function SevenClientCoreSvc() {
    return {
      $get: function ($rootScope,
        SevenGamesSvc,
        $q,
        $filter,
        SevenClientConfig) {
        function SevenClientCore() {
          /**
                     * Data fetched on login
                     *
                     * @type {null}
                     */
          this.loginData = null;
        }

        SevenClientCore.prototype = {

          /**
                     * Run seven client core app
                     *
                     * This method bootstrap client core by creating games
                     * and starting services
                     *
                     * @param loginData
                     * @returns {*}
                     */
          run: function (loginData) {
            this.loginData = loginData;

            var clientAppModules = this.loginData.clientAppModules;
            var games = [];
            var deffered = $q.defer();
            var promises = {};
            var self = this;

            for (var i = 0; i < clientAppModules.length; i++) {
              var module = clientAppModules[i];

              // is game?
              if (module.type === 'product') {
                self._configureGameModule(module);
                games.push(module);
              } else {
                self._configureGeneralModule(module);
              }
            }

            promises.sevenGamesSvc = SevenGamesSvc.init(games);

            $q.all(promises).then(
              function (response) {
                $rootScope.$emit('SevenClientCore:ready');
                deffered.resolve(response);
              },
              function () {
                deffered.reject('SevenClientCore init q.all promise failed');
              }
            );

            return deffered.promise;
          },

          /**
                     * Get all modules fetched on login
                     *
                     * @param {Object} filter
                     * @param {Object} orderBy
                     * @returns {Array}
                     */
          getAllModules: function (orderBy) {
            var generalModules = this.getGeneralModules(orderBy);
            var gameModules = SevenGamesSvc.getGames();
            var activeModules = gameModules.concat(generalModules);

            if (orderBy) {
              activeModules = $filter('orderBy')(activeModules, orderBy);
            }

            return activeModules;
          },

          /**
                     * Return general modules
                     *
                     * @param orderBy
                     * @returns {*|Array}
                     */
          getGeneralModules: function (orderBy) {
            var modules = $filter('filter')(this.loginData.clientAppModules, { type: '!product' });

            if (orderBy) {
              modules = $filter('orderBy')(modules, orderBy);
            }

            return modules;
          },

          /**
                     * Return single module
                     *
                     * @param id
                     * @returns {*}
                     */
          getModule: function (id) {
            var foundModules = $filter('filter')(this.loginData.clientAppModules, { id: id }, true);
            return foundModules.length ? foundModules[0] : false;
          },

          _configureGeneralModule: function (module) {
            module.className = module.id.split(/(?=[A-Z])/).join('-').toLowerCase();
          },

          _configureGameModule: function (module) {
            // add config fetch route
            module.configRoute = this.loginData.configRoutes[module.cpvUuid];

            module.className = module.id.split(/(?=[A-Z])/).join('-').toLowerCase();

            // this is part where we assign game config from SevenClientConfigProvider
            SevenClientConfig.updateProductSettings(module.id, module.local);
            module.local = SevenClientConfig.getProductSettings(module.id);

            return module;
          }
        };

        return new SevenClientCore(SevenGamesSvc);
      }
    };
  }

  angular.module('7Shop.Core')
    .provider('SevenClientCoreSvc', SevenClientCoreSvc);
})(window);
