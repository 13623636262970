(function () {
  angular.module('7Shop.Bootstrap')
    .factory('shopLanugageLoader', shopLanugageLoader);

  function shopLanugageLoader($q, ShopBootstrap) {
    return function () {
      var deferred = $q.defer();
      var bootstrapData = ShopBootstrap.getLoadData();
      if (bootstrapData?.deprecated?.translations) {
        deferred.resolve(bootstrapData.deprecated.translations);
      } else {
        deferred.resolve({});
      }
      return deferred.promise;
    };
  }
})();
