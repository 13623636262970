import router from 'angular-ui-router';
import UserModule from '../user';
import ShopModules from '../index';
import CoreModule from '../../core';
import CommonModule from '../../common';

/**
   * @module "7Shop.Bootstrap"
   */
angular.module('7Shop.Bootstrap', [
  router,
  CommonModule,
  UserModule,
  ShopModules,
  CoreModule
]);
