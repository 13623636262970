/**
* @class CloudflareService
* @memberof module:"7Shop.Core"
*/
/* @ngInject */
function CloudflareService(GravitySettings) {
  const routes = ['7platform.com', 'nsoft.com', 'neosoft.ba', '7Platform.net'];
  this.generateCloudflareUrl = function (url) {
    var cloudflareEnabled = GravitySettings.getModuleDataKeyValue('config', 'cloudflare');
    var cloudflareUrl;
    var parsedUrl;

    if (!cloudflareEnabled) return url;

    try {
      parsedUrl = new URL(url);
    } catch (error) {
      return url;
    }

    cloudflareUrl = parsedUrl.origin;

    for (let i = 0; i < routes.length; i += 1) {
      if (cloudflareUrl.indexOf(routes[i]) !== -1) {
        cloudflareUrl = cloudflareUrl.replace(routes[i], '7platform.info');
        break;
      }
    }

    if ((cloudflareUrl.indexOf('gcm-fra-1.7platform.info') !== -1)// routes like https://gcm-fra-3.7platform.com:8008/get-lb have port changed on cloudflare
    || (cloudflareUrl.indexOf('gcm-fra-2.7platform.info') !== -1)
    || (cloudflareUrl.indexOf('gcm-fra-3.7platform.info') !== -1)
    || (cloudflareUrl.indexOf('cm.7platform.info') !== -1)
    || (cloudflareUrl.indexOf('cm2.7platform.info') !== -1)
    || (cloudflareUrl.indexOf('cm3.7platform.info') !== -1)) {
      cloudflareUrl = cloudflareUrl.replace(':8008', ':8443');
    }

    if (cloudflareUrl.indexOf('sportbook-public-api') !== -1) {
      cloudflareUrl = cloudflareUrl.replace('sportbook-public-api', 'sportsbook-public-api');
    } else if (cloudflareUrl.indexOf('prod-platform-reporting-lb') !== -1) {
      cloudflareUrl = cloudflareUrl.replace('prod-platform-reporting-lb', 'reporting-api');
    }

    return cloudflareUrl + parsedUrl.pathname + parsedUrl.search;
  };
}

export default CloudflareService;
