(function (window) {
  var mapper = {};
  var _7 = {
    getService: function (service) {
      // eslint-disable-next-line no-prototype-builtins
      if (mapper.hasOwnProperty(service)) {
        if (typeof mapper[service] === 'function') {
          return mapper[service]();
        }
        return mapper[service];
      }

      return false;
    },

    registerService: function (name, reference) {
      mapper[name] = reference;
    }
  };
  // eslint-disable-next-line angular/window-service
  window._7 = window._7 || _7;
})(window);
