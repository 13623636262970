import angular from 'angular';
import oclazyload from 'oclazyload';
import Modules from './modulesService';

/**
 * @module "7Shop.Modules"
 */
const moduleName = angular.module('7Shop.Modules', [oclazyload])
  .service('Modules', Modules)
  .name;

export default moduleName;
