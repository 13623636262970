/* eslint-disable no-prototype-builtins */
/* eslint-disable vars-on-top */
/* eslint-disable no-restricted-syntax */
(function () {
  /**
     * Group array of objects by property
     *
     * @example
     *
     * var myList = [
     * {time: '12:00', location: 'mall'    },
     * {time: '9:00',  location: 'store'   },
     * {time: '9:00',  location: 'mall'    },
     * {time: '12:00', location: 'store'   },
     * {time: '12:00', location: 'market'  },
     * ];
     *
     * var result = $filter('groupBy')(myList, 'location');
     *
     * result = [
     *  {time: '12:00', location: 'mall'    },
     *  {time: '9:00',  location: 'mall'    },
     *  {time: '9:00',  location: 'store'   },
     *  {time: '12:00', location: 'store'   },
     *  {time: '12:00', location: 'market'  }
     * ]
     */
  angular.module('7Shop.Common').filter('groupBy', function groupBy() {
    return function (list, prop) {
      var result = [];

      var groups = list.reduce(function (g, item) {
        var val = item[prop];
        if (!g[val]) {
          g[val] = [];
        }
        g[val].push(item);
        return g;
      }, {});

      for (var i in groups) {
        if (groups.hasOwnProperty(i)) {
          groups[i].forEach(function (item) {
            result.push(item);
          });
        }
      }

      return result;
    };
  });
})();
