(function () {
  var KeyMapper = {
    enter: {
      key: 13,
      text: 'Enter'
    },
    shift_left: {
      key: 16,
      text: 'Shift left'
    },
    ctrl_left: {
      key: 17,
      text: 'Shift left'
    },
    alt_left: {
      key: 18,
      text: 'Shift left'
    },
    shift_right: {
      key: 16,
      text: 'Shift right'
    },
    escape: {
      key: 27,
      text: 'Escape'
    },
    space: {
      key: 32,
      text: 'Space'
    },
    letter_c: {
      key: 67,
      text: 'c'
    },
    letter_d: {
      key: 68,
      text: 'd'
    },
    letter_e: {
      key: 69,
      text: 'e'
    },
    letter_f: {
      key: 70,
      text: 'f'
    },
    letter_g: {
      key: 71,
      text: 'g'
    },
    letter_h: {
      key: 72,
      text: 'h'
    },
    letter_j: {
      key: 74,
      text: 'j'
    },
    letter_k: {
      key: 75,
      text: 'k'
    },
    letter_l: {
      key: 76,
      text: 'l'
    },
    letter_m: {
      key: 77,
      text: 'm'
    },
    letter_o: {
      key: 79,
      text: 'n'
    },
    letter_p: {
      key: 80,
      text: 'p'
    },
    letter_q: {
      key: 81,
      text: 'q'
    },
    letter_r: {
      key: 82,
      text: 'r'
    },
    letter_s: {
      key: 83,
      text: 's'
    },
    letter_t: {
      key: 84,
      text: 't'
    },
    letter_w: {
      key: 87,
      text: 't'
    },
    letter_z: {
      key: 90,
      text: 'z'
    },
    letter_i: {
      key: 73,
      text: 'i'
    },
    numpad_plus: {
      key: 107,
      text: 'NumPad +'
    },
    numpad_subtract: {
      key: 109,
      text: 'NumPad -'
    },
    numpad_divider: {
      key: 111,
      text: 'NumPad /'
    },
    number_1: {
      key: 49,
      text: '1'
    },
    number_2: {
      key: 50,
      text: '2'
    },
    number_3: {
      key: 51,
      text: '3'
    },
    number_4: {
      key: 52,
      text: '4'
    },
    Numpad1: {
      key: 97,
      text: '1'
    },
    Numpad2: {
      key: 98,
      text: '2'
    },
    Numpad3: {
      key: 99,
      text: '3'
    },
    Numpad4: {
      key: 100,
      text: '4'
    },
    f1: {
      key: 112,
      text: 'f1'
    },
    f2: {
      key: 113,
      text: 'f2'
    },
    f3: {
      key: 114,
      text: 'f3'
    },
    f4: {
      key: 115,
      text: 'f4'
    },
    f5: {
      key: 116,
      text: 'f5'
    },
    f5_v1: {
      key: 244,
      text: 'f5'
    },
    f6: {
      key: 117,
      text: 'f6'
    },
    f7: {
      key: 118,
      text: 'f7'
    },
    f8: {
      key: 119,
      text: 'f8'
    },
    f9: {
      key: 120,
      text: 'f9'
    },
    f10: {
      key: 121,
      text: 'f10'
    },
    f11: {
      key: 122,
      text: 'f11'
    },
    f12: {
      key: 123,
      text: 'f12'
    },
    plus: {
      key: 187,
      text: '+'
    },
    dash: {
      key: 189,
      text: '-'
    },
    asterisk: {
      key: 106,
      text: '*'
    }
  };

  angular.module('7Shop.Common')
    .constant('KeyMapper', KeyMapper);
})();
