(function () {
  angular.module('7Shop.Common')
    .service('loaderService', loaderService);

  function loaderService(
    $window,
    $rootScope
  ) {
    // keep listeners for backward compatibility
    $rootScope.$on('loader:stop', function (event, message) {
      $window._7Loader.showLoader(message);
    });

    $rootScope.$on('loader:start', function () {
      $window._7Loader.endLoader();
    });

    return $window._7Loader;
  }
})();
