/* eslint-disable radix */
/* eslint-disable no-plusplus */
/* eslint-disable vars-on-top */
(function () {
  angular.module('7Shop.Common')
    .service('focusNext', focusNext);

  function focusNext(
    $timeout
  ) {
    /**
         * Focus element
         */
    return function (tabIndex, select, listenEvents) {
      var focusArr = (tabIndex && tabIndex.toString().split(',')) || [];

      // find element with lowest tab index
      if (tabIndex === false) {
        var foundIndex = null;
        $('*[tabindex]').each(function () {
          var el = $(this);
          if ((!foundIndex
                      || el.attr('tabindex') < foundIndex)
                      && parseInt(el.attr('tabindex')) > -1) {
            foundIndex = el.attr('tabindex');
          }
        });

        if (foundIndex) focusArr.push(foundIndex);
      }

      $timeout(function () {
        for (var i = 0; i < focusArr.length; i++) {
          var $inputElem = $("[tabindex='" + focusArr[i] + "']");

          if ($inputElem.length) {
            var el = $inputElem.first();

            el.focus();

            if (select) el.select();

            if (listenEvents) el.click().focus();

            break;
          }
        }
      });
    };
  }
})();
