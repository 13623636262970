(function () {
  angular.module('7Shop.Common')
    .directive('copyToClipboard', copyToClipboard);

  function copyToClipboard(
    $window,
    $rootScope
  ) {
    return {
      scope: {
        copyToClipboard: '@',
        copyToClipboardNotificationMessage: '@?'
      },
      link: function (scope, element) {
        element.click(function () {
          var body = angular.element($window.document.body);
          var textarea = angular.element('<textarea/>');

          textarea.css({
            position: 'fixed',
            opacity: '0'
          });
          textarea.val(scope.copyToClipboard);
          body.append(textarea);
          textarea[0].select();
          $window.document.execCommand('copy');
          textarea.remove();

          if (scope.copyToClipboardNotificationMessage) {
            $rootScope.$emit('7S:Dialog.ShowModal', {
              message: scope.copyToClipboardNotificationMessage,
              type: 'success',
              delay: 2000
            });
          }
        });
      }
    };
  }
}());
