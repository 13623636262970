/* eslint-disable vars-on-top */
(function () {
  angular.module('7Shop.Common')
    .directive('focusNext', function focusNext(
      /* focusNext */
    ) {
      return {
        restrict: 'A',
        /**
                 * Based on tabindex attribute and passed parent name
                 * find next element and set focus on it
                 * For form inputs, we need to prevent default (i.e. submit)
                 */
        link: function (scope, elem, attrs) {
          // bind to event
          elem.bind(attrs.focusNextEvent, function (e) {
            // attrs.focusNext can have multiple values seperated by comma

            var allowedKeys = attrs.focusNextOnKey.split(',');
            var select = scope.$eval(attrs.focusNextSelect);
            var focusArr = attrs.focusNext.split(',');
            var invalid = angular.isDefined(attrs.focusNextInvalid) ? attrs.focusNextInvalid : 'false';
            var skipFirst = angular.isDefined(attrs.focusNextSkipFirst) ? attrs.focusNextSkipFirst : 'false';
            var $inputElem;

            // only allowed keys are listened
            if (allowedKeys.indexOf(e.which.toString()) >= 0) {
              // Do not focus next if input is invalid
              if (invalid === 'true') {
                return;
              }

              // eslint-disable-next-line no-plusplus
              for (var i = 0; i < focusArr.length; i++) {
                if (i === 0 && skipFirst === 'true') {
                  // eslint-disable-next-line no-continue
                  continue;
                }

                $inputElem = $("[tabindex='" + focusArr[i] + "']");

                if ($inputElem.length) {
                  $inputElem
                    .first()
                    .focus();

                  if (select) $inputElem.first().select();

                  if ($inputElem.is(':focus')) {
                    break;
                  }
                }
              }

              e.preventDefault();
            }
          });
        }
      };
    });
})();
