// import './datepicker/datepickerTemplate.html';
import './app';
import './autoFocus/autoFocusDirective';
import './datepicker/dateSelectedDirective';
import './datepicker/datepickerService';
import './datepicker/datepickerHelperService';
import './datepicker/sDatepickerDirective';
import './datepicker/touch/dateRangeSelectedDirective';
import './directives/conditionalFocusDirective';
import './directives/copyToClipboardDirective';
import './directives/customDateInput';
import './directives/customSelectDirective';
import './directives/disableClickDirective';
import './directives/disableKeyboard';
import './directives/dropdownInput/dropdownInputDirective';
import './directives/dropdownModal/dropdownModal';
import './directives/focusFirstInputDirective';
import './directives/gallery/galleryDirective';
import './directives/inputRestoreValueDirective';
import './directives/isDuplicateDirective';
import './directives/multiSelect/multiSelectDirective';
import './directives/multiSelect/multiSelectInput/multiSelectInputDirective';
import './directives/multiSelect/multiSelectItems/multiSelectItemsDirective';
import './directives/multiSelect/multiSelectItems/multiSelectItemsModal/multiSelectItemsController';
import './directives/onlyNumbersDirective';
import './directives/numberDelimiter';
import './directives/overlayNotification/overlayNotification';
import './directives/rightClickDirective';
import './directives/scrollableDirective';
import './directives/sInputSwitch/sInputSwitchDirective';
import './directives/countdown/countdownDirective';
import './filter/trustHtmlFilter';
import './focusNext/focusNextDirective';
import './focusNext/focusNextSvc';
import './groupBy/groupByFilter';
import './hooks/hooksManagerService';
import './loader/loaderDirective';
import './loader/loaderService';
import './messaging/clientMessagingService';
import './modal/modalController';
import './modal/modalService';
import './oddsConverter/oddsConverterFilter';
import './page_nav/sPageNavComponent';
import './popup/popUpDirective';
import './popup/popUpNotificationDirective';
import './popup/popupService';
import './services/localStorage';
import './services/keyMapperConstants';
import './services/touchProvider';
import './seven-currency/sevenCurrencyFilter';
import './seven-currency/sevenCurrencySvc';
import './tabs/sTabsComponent';
import './tabs/sTabsPaneComponent';
import './loadImageDirective';
import './sevenClientLibConfigProvider';
import './valueCheckDirective';

export default '7Shop.Common';
