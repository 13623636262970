(function () {
  /* eslint-disable angular/controller-as, angular/controller-name */
  angular.module('7Shop.Common')
    .controller('ModalController', ModalController);

  function ModalController(
    $scope,
    close,
    templateUrl,
    title,
    id,
    showClose,
    api
  ) {
    $scope.close = close;
    $scope.templateUrl = templateUrl;
    $scope.title = title;
    $scope.id = id;
    $scope.showClose = showClose;
    $scope.exposedData = angular.extend(api, {
      close: $scope.close
    });
  }
})();
