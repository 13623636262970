/**
 * @desc Date input directive that can be used on text input for validating date input and setting valid one
 * @example <input custom-date-input custom-date-part="Day" custom-date-data="{day: 1, month: 1, year: 1990}">
 */
(function () {
  angular.module('7Shop.Common').directive('customDateInput', customDateInput);

  function customDateInput() {
    return {
      scope: {
        customDatePart: '@',
        customDateData: '=',
        customYearOffset: '@'
      },
      link: customDateLink,
      controller: CustomDateController,
      controllerAs: 'dateInputVm',
      bindToController: true
    };
  }

  function customDateLink(scope, el, attr, ctrl) {
    el.bind('blur', function () {
      ctrl.validateDatePart();
    });
  }

  CustomDateController.$inject = ['datepickerHelperService'];

  function CustomDateController(datepickerHelperService) {
    var dateInputVm = this;
    var monthDays;

    var dateValidations = {
      validateDay: function () {
        dateInputVm.customDateData.day = datepickerHelperService.validateDays(monthDays, dateInputVm.customDateData.day);
      },

      validateMonth: function () {
        dateInputVm.customDateData.month = datepickerHelperService.validateMonth(dateInputVm.customDateData.month);
        dateInputVm.customDateData.day = datepickerHelperService.validateDays(monthDays, dateInputVm.customDateData.day);
      },

      validateYear: function () {
        dateInputVm.customDateData.year = datepickerHelperService.validateYear(
          dateInputVm.customDateData.year,
          parseInt(dateInputVm.customYearOffset, 10)
        );
        dateInputVm.customDateData.month = datepickerHelperService.validateMonth(dateInputVm.customDateData.month);
        dateInputVm.customDateData.day = datepickerHelperService.validateDays(monthDays, dateInputVm.customDateData.day);
      }
    };

    dateInputVm.validateDatePart = function () {
      monthDays = datepickerHelperService.getMonthDays(dateInputVm.customDateData.month, dateInputVm.customDateData.year);
      dateValidations['validate' + dateInputVm.customDatePart]();
    };
  }
})();
