import templateUrl from './sInputSwitchTemplate.html';

function sInputSwitchDirective() {
  return {
    restrict: 'E',
    scope: {
      model: '='
    },
    templateUrl: templateUrl
  };
}

export default sInputSwitchDirective;
