(function () {
  angular.module('7Shop.Common')
    .service('datepickerHelperService', function datepickerHelperService($filter) {
      return {

        /**
                 * Get maximum day of selected month
                 *
                 * @param month
                 * @param year
                 * @returns {number}
                 */
        getMonthDays: function (month, year) {
          // We want a leap year if none is specified
          if (!year) {
            year = 4;
          }

          return new Date(year, month, 0).getDate();
        },

        /**
                 * Check if year is valid
                 *
                 * @param selectedYear
                 * @param offset {Number} - Offset from current year
                 * @param oldValue {String}
                 * @returns {*}
                 */

        validateYear: function (selectedYear, offset, oldValue) {
          var currentYear = new Date().getFullYear();
          if (offset) {
            currentYear += offset;
          }
          // eslint-disable-next-line radix
          if ((parseInt(selectedYear) > parseInt(currentYear) || parseInt(selectedYear) < 1900) || selectedYear === '') {
            return oldValue || currentYear;
          }

          return selectedYear;
        },

        /**
                 * Check if month is valid
                 *
                 * @param selectedMonth
                 * @param oldValue {String}
                 * @returns {*}
                 */

        validateMonth: function (selectedMonth, oldValue) {
          // eslint-disable-next-line radix
          if (parseInt(selectedMonth) > 12) {
            return oldValue || '12';
          // eslint-disable-next-line radix
          } if (!selectedMonth || parseInt(selectedMonth) === 0) {
            return oldValue || '1';
          }
          return this.preecedingZero(selectedMonth);
        },

        /**
                 * Check if selected day is responding to maximum num of day in month
                 *
                 * @param monthDays
                 * @param selectedDay
                 * @param oldValue {String}
                 * @returns {*}
                 */

        validateDays: function (monthDays, selectedDay, oldValue) {
          // eslint-disable-next-line radix
          if (parseInt(monthDays) < (selectedDay)) {
            return oldValue || this.preecedingZero(monthDays);
          // eslint-disable-next-line radix
          } if (!selectedDay || parseInt(selectedDay) === 0) {
            return oldValue || '01';
          }
          return this.preecedingZero(selectedDay);
        },

        /**
                 * Return day substracted for 'n' days from current date
                 * It's used to get the date range (last month, last week)
                 *
                 * @param days
                 * @returns {Date}
                 */

        substractDay: function (days) {
          var dateOffset = (24 * 60 * 60 * 1000) * days; // for n days
          var today = new Date().getTime();
          var myDate = new Date(today - dateOffset);

          return myDate;
        },

        /**
                 * Return current date in format YYYY-mm-dd
                 */
        getCurrentDate: function () {
          var today = new Date();

          return today;
        },

        increaseDate: function (days) {
          var dateOffset = (24 * 60 * 60 * 1000) * days; // for n days
          var today = new Date().getTime();
          var myDate = new Date(today + dateOffset);

          return myDate;
        },

        /**
                 * Return day/month in 2 digit format
                 * @param date
                 * @returns {string}
                 */

        preecedingZero: function (date) {
          date = String(date);

          // eslint-disable-next-line radix
          if (parseInt(date) < 10 && date.length < 2) {
            return '0' + date;
          }
          return date;
        },

        /**
                 * Sets date to first in month
                 * @param offset {Number} - 0 for current month, -1 for previous etc
                 * @returns {*}
                 */
        setMonthStart: function (offset) {
          return this.setExactDayInMonth(offset, 1);
        },

        /**
                 * Sets date to last in month
                 * @param offset {Number} - 0 for current month, -1 for previous etc
                 * @returns {*}
                 */
        setMonthEnd: function (offset) {
          // increase month for one, because 0 day goes back to previous month
          // eslint-disable-next-line no-plusplus
          return this.setExactDayInMonth(++offset, 0);
        },

        /**
                 * Sets date to exact day in month
                 * @param monthOffset {Number} - 0 for current month, -1 for previous etc
                 * @param day {Number} - Exact day in month
                 * @returns {string}
                 */
        setExactDayInMonth: function (monthOffset, day) {
          var currentMonth = new Date().getMonth();
          var newDate = new Date();

          currentMonth += monthOffset || 0;
          newDate.setMonth(currentMonth, day);

          return newDate;
        },

        createDate: function (data, format) {
          var date = new Date(data.year, data.month, data.day);

          return $filter('date')(date, format);
        }
      };
    });
})();
