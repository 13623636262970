(function () {
  angular.module('7Shop.Common')
    .directive('disableClick', function disableClick() {
      return {
        restrict: 'A',
        scope: {
          disableClick: '='
        },
        link: function (scope, elem) {
          scope.$watch('disableClick', function () {
            elem.prop('disabled', scope.disableClick);
          });
        }
      };
    });
})();
