import angular from 'angular';
import modulesModule from '../index';

import IframePubSub from './iframePubSubService';

/**
 *  @module "7Shop.IframePubSub"
 */
const moduleName = angular.module('7Shop.IframePubSub', [modulesModule])
  .service('IframePubSub', IframePubSub)
  .name;

export default moduleName;
