(function () {
  angular.module('7Shop.Common').directive('onlyNumbers', function onlyNumbers() {
    return {
      require: 'ngModel',
      link: function (scope, element, attrs, modelCtrl) {
        modelCtrl.$parsers.push(function (inputValue) {
          var transformedInput = inputValue;
          var returnString = attrs.onlyNumbersReturnAsString;
          // this next if is necessary for when using ng-required on your input.
          // In such cases, when a letter is typed first, this parser will be called
          // again, and the 2nd time, the value will be undefined
          if (angular.isUndefined(inputValue)) return '';
          if (angular.isNumber(inputValue)) return inputValue;

          if (attrs.allowDecimal !== 'true') {
            // replace everything with space that is not number
            transformedInput = inputValue.replace(/[^0-9]/g, '');
          }

          if (attrs.allowDecimal === 'true') {
            if (!/^[0-9]+(\.|,)?([0-9]+)?$/g.test(inputValue)
                          && inputValue !== '') {
              // if regex is not right splice last char
              // e.g. two dots
              transformedInput = inputValue.slice(0, inputValue.length - 1);
            }

            transformedInput = transformedInput.replace(',', '.');
          }

          // eslint-disable-next-line
          if (transformedInput != inputValue) {
            modelCtrl.$setViewValue(transformedInput);
            modelCtrl.$render();
          }

          return transformedInput || !returnString ? parseFloat(transformedInput) : transformedInput;
        });
      }
    };
  });
})();
