(function () {
  angular.module('7Shop.Common').directive('dateRangeSelected', function dateRangeSelected($rootScope) {
    return {
      restrict: 'A',
      link: function (scope, elem, attrs) {
        elem.on('click', function () {
          $rootScope.$broadcast('7S:Datepicker.DateRangeSelected', attrs.dateRangeSelected);
        });
      }
    };
  });
})();
