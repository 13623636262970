/**
* @class CmsFormsParserService
* @memberof module:"7Shop.Core"
*/
/* @ngInject */
function CmsFormsParserService($translate, datepickerHelperService) {
  /**
     * @memberOf CmsFormsParserService
     */
  var self = this;
  this.generateDateControls = function () {
    var list = [];
    var controls = {
      dateDay: {
        datePart: 'Day',
        name: 'date_day'
      },
      dateMonth: {
        datePart: 'Month',
        name: 'date_month'
      },
      dateYear: {
        datePart: 'Year',
        name: 'date_year'
      }
    };

    angular.forEach(controls, function (control) {
      list.push({
        control: {
          name: 'input',
          type: 'text'
        },
        name: 'dateData',
        label: $translate.instant(control.name),
        placeholder: $translate.instant(control.name),
        source: control,
        readonly: false,
        visible: true,
        required: true
      });
    });

    return list;
  };

  this.getDateFormatted = function (dateObj) {
    var dateCopy = angular.copy(dateObj);
    dateCopy.month -= 1; // Months are zero based. ToDo: Fix this with new version of datepicker
    return datepickerHelperService.createDate(dateCopy, 'yyyy-MM-dd');
  };

  this.parseCMSForm = function (form) {
    var focusIdx = 1;
    var fields = [];

    angular.forEach(form.properties, function (el) {
      // Convert to valid RegEx
      if (el.pattern) {
        el.pattern = RegExp.apply(
          undefined,
          /^\/(.*)\/(.*)/.exec(el.pattern).slice(1)
        );
      }

      if (form.required.includes(el.name)) {
        el.required = true;
      } else {
        el.required = false;
      }

      if (el.control === 'controls') {
        el.controls = self.generateDateControls();

        if (el.controls) {
          angular.forEach(el.controls, function (control) {
            control.focusIdx = focusIdx;
            focusIdx += 1;
            control.nextFocusIdx = focusIdx;
          });
        }
      } else if (el.visible && el.control !== 'file') {
        el.focusIdx = focusIdx;
        focusIdx += 1;
        el.nextFocusIdx = focusIdx;
      }

      fields.push(el);
    });

    return fields;
  };
}

export default CmsFormsParserService;
