/**
 * @class userService
 * @memberOf module:"7Shop.User"
 */
/* @ngInject */
function userService() {
  var loginData = {};

  var ROLE_SERVICE_PROVIDER = 'SevenShop:';

  this.state = {
    loggedIn: false
  };

  /**
   * @memberOf module:"7Shop.User".userService
   *
   * @returns {SevenOperator}
   */
  this.getUserData = function () {
    return loginData.user;
  };

  /**
   * @memberOf module:"7Shop.User".userService
   */
  this.setData = function (data) {
    loginData = data;
  };

  /**
   * @memberOf module:"7Shop.User".userService
   */
  this.getData = function () {
    return loginData;
  };

  /**
   * Check does user have permission/role
   *
   * @memberOf module:"7Shop.User".userService
   * @param role
   * @return {Boolean}
   */
  this.hasRole = function (role) {
    return loginData.roles.indexOf(ROLE_SERVICE_PROVIDER + role) >= 0;
  };
}

export default userService;
