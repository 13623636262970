(function () {
  angular.module('7Shop.Core')
    .service('TemplateLoaderService', TemplateLoader);

  function TemplateLoader(Touch) {
    this.getTemplate = function (definition) {
      var isTouchMode = Touch.isTouch();
      var templateUrl = definition.templateUrl;
      if (angular.isObject(templateUrl)) {
        templateUrl = (isTouchMode ? templateUrl.mobile : templateUrl.default) || templateUrl.default;
        return templateUrl;
      }
      return templateUrl;
    };
  }
})();
