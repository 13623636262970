(function () {
  angular.module('7Shop.Common')
    .factory('DatepickerService', datepickerService);

  function datepickerService(
    $translate,
    $filter
  ) {
    function formatDate(date) {
      return $filter('date')(date, 'yyyy-MM-dd');
    }

    return {
      isDateInRange: function (date, range) {
        var dateFormatted = formatDate(date);
        var rangeStartFormatted = formatDate(range.start);
        var rangeEndFormatted = formatDate(range.end);

        return (moment(dateFormatted).isSameOrAfter(rangeStartFormatted)
                  && moment(dateFormatted).isSameOrBefore(rangeEndFormatted));
      },
      getSelectedDateRange: function (date, range, init) {
        var rangeStartFormatted = formatDate(range.start);
        var rangeEndFormatted = formatDate(range.end);

        // If a single date was previously selected
        // and this is not first-time selection,
        // current selection should expand range - forward or back
        if (moment(rangeStartFormatted).isSame(rangeEndFormatted) && !init) {
          if (moment(date).isBefore(range.start)) {
            range.start = angular.copy(date);
          } else if (moment(date).isAfter(range.start)) {
            range.end = angular.copy(date);
          }
        } else {
          // If range of multiple days was previously selected,
          // current selection should reset range to a single day
          range.start = angular.copy(date);
          range.end = angular.copy(date);
        }

        return range;
      },
      getRangeShortcuts: function () {
        return [{
          name: $translate.instant('main.last_month'),
          value: 'lastMonth',
          callback: 'getLastMonthRange'
        }, {
          name: $translate.instant('main.this_month'),
          value: 'currentMonth',
          callback: 'getCurrentMonthRange'
        }, {
          name: $translate.instant('main.last_week'),
          value: 'lastWeek',
          callback: 'getLastWeekRange'
        }, {
          name: $translate.instant('main.this_week'),
          value: 'currentWeek',
          callback: 'getCurrentWeekRange'
        }];
      },
      getLastMonthRange: function () {
        var range = {
          start: new Date(),
          end: new Date()
        };

        range.start.setMonth(range.start.getMonth() - 1, 1);
        range.end.setDate(0);
        return range;
      },
      getCurrentMonthRange: function () {
        var range = {
          start: new Date(),
          end: new Date()
        };

        range.start.setDate(1);
        return range;
      },
      getCurrentWeekRange: function () {
        var range = {
          start: new Date(),
          end: new Date()
        };
        var startOffset = range.start.getDay() ? range.start.getDay() - 1 : 6;

        range.start.setDate(range.start.getDate() - startOffset);
        return range;
      },
      getLastWeekRange: function () {
        var range = {
          start: new Date(),
          end: new Date()
        };
        var endOffset = range.end.getDay() ? range.end.getDay() : 7;

        range.start.setDate(range.end.getDate() - endOffset - 6);
        range.end.setDate(range.end.getDate() - endOffset);
        return range;
      }
    };
  }
})();
