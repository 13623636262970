/* eslint-disable one-var */
/* eslint-disable no-lonely-if */
/* eslint-disable no-plusplus */
(function () {
  /**
   * @desc Countdown directive optimized to skip digest cycle
   * @example <div countdown></div>
   */
  angular.module('7Shop.Common')
    .directive('countdown', countdown);

  function countdown() {
    return {
      scope: {
        vGame: '=', // value which be be increased/decreased
        maxValue: '=', // max value for startingValue, in case we are decreasing it will be zero
        unit: '@', // how big is each increased/decreased
        formatter: '&' // function to format output to view
      },
      link: link,
      controller: CountdownController,
      controllerAs: 'vm',
      bindToController: true // because the scope is isolated
    };
  }

  CountdownController.$inject = ['$scope', '$element', '$filter', '$interval'];

  function CountdownController($scope, $element, $filter, $interval) {
    var vm = this,
      intervalCounter = null,
      currentValue = '',
      formatter = angular.noop,
      oneUnit = 1000,
      direction = 'down',
      extreme = 0;

    function counter() {
      if (direction === 'up') {
        if (currentValue <= extreme) {
          currentValue++;
        } else {
          vm.stopCounter();
        }
      } else {
        if (currentValue > extreme) {
          --currentValue;
        } else {
          vm.stopCounter();
        }
      }
      $element.text(formatter(currentValue));
    }

    this.$onInit = function () {
      oneUnit = angular.isDefined(vm.unit) ? parseFloat(vm.unit) : 1000;
      // decide are we gonna increase/decrease currentValue
      direction = vm.maxValue ? 'up' : 'down';
      extreme = vm.maxValue ? vm.maxValue : 0;
      currentValue = vm.vGame;
      formatter = vm.formatter() ? vm.formatter() : function (value) {
        return $filter('date')(new Date(value * 1000), 'mm:ss');
      };
    };

    this.startCounter = function () {
      currentValue = vm.vGame.data.startingIn;
      intervalCounter = $interval(counter, oneUnit, false, false);
    };

    this.stopCounter = function () {
      $interval.cancel(intervalCounter);
      intervalCounter = null;
    };

    this.setNewValue = function () {
      this.stopCounter(); // stop last game countdown
      currentValue = vm.vGame.data.startingIn;
      $element.text(formatter(currentValue));
    };
  }

  function link(scope, el, attr, ctrl) {
    scope.$watch('vm.vGame.data.displayRoundNumber', function (newValue) {
      if (angular.isDefined(newValue)
        && angular.isDefined(ctrl.vGame.data.startingIn)) {
        ctrl.setNewValue();
        ctrl.startCounter();
      }
    });
  }
})();
