import iframePubSubModule from './modules/iframe_pub_sub';
import commonModule from './common';

angular.module('7Shop', [
  '7Shop.Bootstrap',
  iframePubSubModule,
  commonModule
]).config(function (
  $provide,
  $httpProvider,
  $compileProvider,
  $logProvider,
  $translateProvider,
  $stateProvider,
  $urlRouterProvider,
  ShopEnvironmentProvider
) {
  var isDebugMode = ShopEnvironmentProvider.isDebugMode();

  // disable debug see https://docs.angularjs.org/guide/production#disabling-debug-data
  $compileProvider.debugInfoEnabled(isDebugMode);
  $compileProvider.commentDirectivesEnabled(false);
  $compileProvider.cssClassDirectivesEnabled(false);
  $logProvider.debugEnabled(isDebugMode);
  $httpProvider.defaults.headers.common['content-type'] = 'application/json';
  $httpProvider.defaults.withCredentials = true;
  $httpProvider.interceptors.push('sevenInterceptor');
  $httpProvider.interceptors.push('tokenInterceptor');
  $httpProvider.interceptors.push('gravityInterceptor');

  $provide.decorator('$exceptionHandler', function exceptionHandlerDecroator(
    $delegate,
    $log,
    $injector,
    GravitySettings,
    ShopEnvironment
  ) {
    // See https://github.com/angular/angular.js/blob/v1.4.7/src/minErr.js
    var angularPattern = /^\[((?:[$a-zA-Z0-9]+:)?(?:[$a-zA-Z0-9]+))\] (.*?)\n?(\S+)$/;

    function isEnabled() {
      var sentryCmsConfig = GravitySettings.getModuleDataKeyValue('config', 'sentry');
      return !ShopEnvironment.isDebugMode()
      && (sentryCmsConfig && sentryCmsConfig.enabled === true);
    }

    return function (ex, cause) {
      var sentry;
      var Modules = $injector.get('Modules');

      if (isEnabled()) {
        import(/* webpackChunkName: "integrations.sentry" */ './modules/sentry')
          .then((mod) => {
            Modules.getLoader().inject(mod.default)
              .then(function () {
              // this is only ran once wen first error occures
                sentry = $injector.get('sentry');
                sentry.withScope(function (scope) {
                  if (cause) {
                    scope.setExtra('cause', cause);
                  }
                  scope.addEventProcessor(function (event) {
                    var exception = event.exception && event.exception.values && event.exception.values[0];
                    var matches;

                    if (exception) {
                      matches = angularPattern.exec(exception.value || '');
                      if (matches) {
                        // This type now becomes something like: $rootScope:inprog
                        exception.type = matches[1];
                        exception.value = matches[2];
                        event.message = exception.type + ': ' + exception.value;
                        // auto set a new tag specifically for the angular error url
                        event.extra = angular.extend({}, event.extra, { angularDocs: matches[3].substr(0, 250) });
                      }
                    }
                    return event;
                  });
                  sentry.captureException(ex);
                });
              });
          })
          .catch((err) => {
            $log.error('[Sportsbook.Bootstrap] Failed to load sentry integration', {
              upstream_code: err.data?.httpCode || err.data?.code || err.response?.status || err.status,
              upstream_message: err.data?.message || err.response?.message || err.message || err.statusText
            });
          });
      }

      $delegate(ex, cause);
    };
  });

  $translateProvider.translations(
    'local',
    {} // we do not have translation in this moment, they will be loaded after ShopBootstrap.boot is done
  );
  $translateProvider.useSanitizeValueStrategy(null);
  $translateProvider.preferredLanguage('local');
  $translateProvider.useLoader('shopLanugageLoader', {});

  $urlRouterProvider.otherwise('/');

  $stateProvider.state('app', {
    url: '/',
    template: '<div class="w-100 h-100" ui-view=""></div>',
    controllerAs: 'app',
    controller: function (
      $rootScope,
      ShopBootstrap
    ) {
      ShopBootstrap.loadMainApp();
      /**
      * Emits event when Shop has loaded neccessry data and it is
      * ready for interaction
      * @event "7S:App.Ready"
      */
      $rootScope.$emit('7S:App.Ready', {});
    },
    resolve: {
      bootData: function (
        ShopEnvironment,
        ShopBootstrap
      ) {
        const productDisplId = ShopEnvironment.getUrlParameter('productDisplayId');
        return ShopBootstrap.boot(productDisplId);
      }
    }
  });
}).run(function (
  $rootScope,
  $exceptionHandler
) {
  // Because ui-route resolve doesn't throw error, this is a workaround to catch them
  $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
    $exceptionHandler(error);
  });
});
