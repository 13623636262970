import pageNavTemplate from './page_nav.html';

(function () {
  angular.module('7Shop.Common').component(
    'sPageNav',
    {
      controller: sPageNavController,
      bindings: {
        items: '<',
        options: '<',
        limit: '<?'
      },
      templateUrl: pageNavTemplate
    }
  );

  function sPageNavController() {
    var ctrl = this;

    ctrl.$onInit = function () {
      ctrl.navBegin = 0;
      ctrl.limit = ctrl.limit || 4;
      ctrl.tabsNavigable = ctrl.limit <= ctrl.items.length;
    };

    ctrl.prevTab = function () {
      if (ctrl.navBegin === 0) return;
      ctrl.navBegin -= 1;
    };

    ctrl.nextTab = function () {
      if (ctrl.items.length - ctrl.limit - ctrl.navBegin <= 0) return;
      ctrl.navBegin += 1;
    };
  }
})();
