import angular from 'angular';
import nabValidator from './validatorService';
import NabValidationConfig from './validatorProvider';
import ValidatorRules from './validatorRulesService';

const moduleName = angular
  .module('nabValidation', [])
  .provider('NabValidationConfig', NabValidationConfig)
  .factory('ValidatorRules', ValidatorRules)
  .service('nabValidator', nabValidator)
  .name;

export default moduleName;
