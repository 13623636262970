import angular from 'angular';
import commonModule from '../../common';

import userService from './userService';

/**
 * @module "7Shop.User"
 */
const moduleName = angular.module('7Shop.User', [
  commonModule
])
  .service('userService', userService)
  .name;

export default moduleName;
