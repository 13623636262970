export default function autoSelectText() {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, elem, attrs, modelCtrl) {
      var oldValue = null;
      var keyCode = null;

      elem.focus(function () {
        oldValue = elem.val();
        elem.select();
      });

      elem.on('keypress', function (e) {
        oldValue = elem.val();
        keyCode = e.which;
      });

      elem.click(function () {
        oldValue = elem.val();
        elem.select();
      });

      elem.blur(function () {
        if ([43, 107, 187].indexOf(keyCode) >= 0) {
          modelCtrl.$setViewValue(oldValue);
          modelCtrl.$render();
        }
      });
    }
  };
}
