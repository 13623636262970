(function () {
  angular.module('7Shop.Common')
    .provider('Touch', Touch);

  function Touch() {
    var self = this;
    this.state = {
      touch: false
    };

    this.setInTouch = function () {
      this.state.touch = true;
    };

    this.isTouch = function () {
      return this.state.touch;
    };

    this.$get = function () {
      return self;
    };
  }
})();
