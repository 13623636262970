import './key-events-directive.scss';

/**
* @class keyEvents
* @memberof module:"7Shop.Core"
*/
/* @ngInject */
function keyEvents(
  $document,
  $log,
  KeyEventsSvc
) {
  return {
    priority: 500,
    template: '<input type="text" class="s-key-events__input">',
    link: function () {
      var onKeyDown = function (e) {
        $log.debug('[Sportsbook.Common] keyEvents keydown', {
          keyCode: e.keyCode
        });
        KeyEventsSvc.processKeyEvent(e);
      };

      // listen for all keydowns trigered in app
      $document
        .on('keydown', onKeyDown);
    }
  };
}

export default keyEvents;
