import templateUrl from './dropdownModalTemplate.html';

(function () {
  angular.module('7Shop.Common')
    .directive('sDropdownModal', sDropdownModal);

  function sDropdownModal(Modal) {
    return {
      scope: {
        sDropdownModalData: '=',
        sDropdownModalTitle: '@',
        sDropdownModalSelect: '=',
        sDropdownModalSelectedData: '='
      },
      link: function (scope, element) {
        element.on('click', function () {
          Modal.show({
            id: 'sDropdownModal',
            callback: function (modal) {
              modal.scope.data = scope.sDropdownModalData;
              modal.scope.selectData = scope.sDropdownModalSelect;
              modal.scope.selectedData = scope.sDropdownModalSelectedData;
            },
            inputs: {
              showClose: true,
              title: scope.sDropdownModalTitle,
              templateUrl: templateUrl
            }
          });
        });
      }
    };
  }
})();
