(function () {
  angular.module('7Shop.Common').component('sTabsPane', {
    transclude: true,
    template: '<div class="s-tabs-pane w-100 h-100" ng-show="$ctrl.selected" ng-transclude></div>',
    require: {
      tabsCtrl: '^sTabs'
    },
    bindings: {
      name: '<',
      disabled: '<'
    },
    controller: sTabsPane
  });

  function sTabsPane() {
    this.$onInit = function () {
      this.tabsCtrl.addPane(this);
    };
  }
})();
