import templateUrl from './sDatepicker.html';
import './datepickerTemplate.html';
import './touch/datepickerTemplate.html';
import './daypickerTemplate.html';
import './touch/daypickerTemplate.html';

(function () {
  angular.module('7Shop.Common').directive(
    'sDatepicker',
    function sDatepicker(Touch) {
      return {
        scope: {
          model: '=',
          options: '=',
          dateFormat: '@?',
          dateRangeEnabled: '@?',
          dateRange: '=?',
          disabled: '=?'
        },
        templateUrl: templateUrl,
        compile: function () {
          return {
            pre: function (scope) {
              scope.isTouch = Touch.isTouch();
              scope.datepickerTemplateUrl = scope.isTouch
                ? '/common/datepicker/touch/datepickerTemplate.html'
                : '/common/datepicker/datepickerTemplate.html';
              scope.dateFormat = scope.dateFormat || 'dd.MM.yyyy';
            },
            post: function (scope) {
              scope.toggle = function () {
                scope.options.isOpen = !scope.options.isOpen;
              };
            }
          };
        }
      };
    }
  );
})();
